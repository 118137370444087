import { useState } from "react";
import { Box, CircularProgress, Grid } from "@mui/material";
import { useFormContext } from "react-hook-form";

import { ARFormValues } from "@/interfaces";
import { useAuthorization } from "@/context";
import { MapRender } from "./MapRender";
import { MapUpload } from "./MapUpload";

export function MapTab() {
  const { isRequestor } = useAuthorization();
  const { watch, setValue } = useFormContext<ARFormValues>();

  const [mapLoaded, setMapLoaded] = useState(false);
  const isMapExist = watch("mapUploaded");

  const showLoader = !mapLoaded && isMapExist;
  return (
    <Grid data-testid="map-tab-display" id="ar-map-grid" container direction={"row"} sx={{ height: "100%" }}>
      <Grid item flex={1}>
        <Box display="flex" justifyContent="center" alignItems="center" height="100%">
          {showLoader && <CircularProgress className="circularProgress" />}
          {isMapExist && <MapRender mapLoaded={mapLoaded} setMapLoaded={setMapLoaded} />}
          {isMapExist === false && isRequestor && (
            <MapUpload setIsMapExist={(updatedValue) => setValue("mapUploaded", updatedValue)} />
          )}
        </Box>
      </Grid>
    </Grid>
  );
}
