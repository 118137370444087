import {
  FocusEventHandler,
  KeyboardEventHandler,
  forwardRef,
  useEffect,
  useImperativeHandle,
  KeyboardEvent,
  FocusEvent
} from "react";
import { Box, SxProps } from "@mui/material";
import { Editor, EditorContent, useEditor } from "@tiptap/react";
import { Editor as InnerEditor } from "@tiptap/core/dist/packages/core/src/Editor";
import Placeholder from "@tiptap/extension-placeholder";
import StarterKit from "@tiptap/starter-kit";
import Underline from "@tiptap/extension-underline";
import Mention from "@tiptap/extension-mention";
import TextAlign from "@tiptap/extension-text-align";
import Highlight from "@tiptap/extension-highlight";
import TableCell from "@tiptap/extension-table-cell";
import TableHeader from "@tiptap/extension-table-header";
import TableRow from "@tiptap/extension-table-row";
import Link from "@tiptap/extension-link";
import OrderedList from "@tiptap/extension-ordered-list";
import FontFamily from "@tiptap/extension-font-family";
import TextStyle from "@tiptap/extension-text-style";

import { FontSize, MentionsExtension, PreserveTableAttributes } from "./extensions";
import { MenuBar } from "./MenuBar";
import "./RichTextEditor.scss";

interface RichTextInputProps {
  onChange?: (editor: InnerEditor) => void;
  defaultValue?: string | undefined;
  readOnly?: boolean;
  placeholder?: string;
  height?: string | number;
  hideMenuBar?: boolean;
  onFocus?: FocusEventHandler<HTMLDivElement> | undefined;
  onBlur?: FocusEventHandler<HTMLDivElement> | undefined;
  onKeyDown?: KeyboardEventHandler<HTMLDivElement> | undefined;
  hasMention?: boolean;
  autoFocus?: boolean;
  editorWrapperSX?: SxProps;
  "data-testid"?: string;
  className?: string;
}

export interface RichTextEditorRef {
  clearContent: () => void;
  getContent: (isHTML: boolean) => string;
  focus: () => void;
  blur: () => void;
  reset: (text: string) => void;
}

export const RichTextEditor = forwardRef<RichTextEditorRef, RichTextInputProps>(
  (
    {
      onChange,
      defaultValue,
      readOnly,
      placeholder,
      hideMenuBar,
      onFocus,
      onBlur,
      onKeyDown,
      hasMention,
      autoFocus,
      editorWrapperSX,
      "data-testid": dataTestId,
      className
    }: RichTextInputProps,
    ref
  ) => {
    const renderMenuBar = (editor: Editor | null, readOnly: boolean | undefined) => {
      if (hideMenuBar) return null;

      return <MenuBar editor={editor} disabled={readOnly} />;
    };

    const extensions = [
      StarterKit.configure({
        orderedList: false
      }),
      Underline,
      Highlight.configure({ multicolor: true }),
      PreserveTableAttributes.configure({
        resizable: true
      }),
      TableRow,
      TableHeader,
      TableCell,
      TextAlign.configure({
        types: ["heading", "paragraph"]
      }),
      Link,
      Placeholder.configure({
        placeholder
      }),
      TextStyle,
      FontFamily,
      FontSize,
      OrderedList.configure({
        HTMLAttributes: {
          class: className
        }
      })
    ];

    if (hasMention) {
      extensions.push(
        Mention.configure({
          HTMLAttributes: {
            class: "mention"
          },
          suggestion: MentionsExtension
        })
      );
    }

    const editor = useEditor({
      editable: !readOnly,
      extensions: extensions,
      onUpdate: ({ editor }) => {
        if (editor.getHTML() !== defaultValue) {
          onChange?.(editor);
        }
      },
      content: defaultValue ?? "",
      editorProps: {
        transformPastedText(text) {
          return text.replace(/&nbsp;/g, " ");
        },
        transformPastedHTML(html) {
          return html.replace(/&nbsp;/g, " ");
        }
      }
    });

    useEffect(() => {
      editor?.setEditable(!readOnly);
    }, [editor, readOnly]);

    useEffect(() => {
      if (autoFocus) {
        editor?.commands.focus();
      }
    }, [autoFocus, editor]);

    const handleOnKeyDown = (event: KeyboardEvent<HTMLDivElement>) => {
      onKeyDown?.(event);
    };

    useImperativeHandle(ref, () => ({
      clearContent() {
        editor?.commands.clearContent(true);
        onBlur?.({} as FocusEvent<HTMLDivElement>);
        editor?.commands.blur();
      },
      getContent(isHTML: boolean) {
        return (isHTML ? editor?.getHTML() : editor?.getText()) ?? "";
      },
      focus() {
        editor?.commands.focus();
      },
      blur() {
        editor?.commands.blur();
      },
      reset(text: string) {
        editor?.commands.setContent(text);
      }
    }));

    // Menubar only can disabled, can't be readOnly
    return (
      <>
        {renderMenuBar(editor, readOnly)}
        <Box sx={{ height: "100%", ".ProseMirror": { height: "100%" }, ...editorWrapperSX }}>
          <EditorContent
            editor={editor}
            onFocus={onFocus}
            onBlur={onBlur}
            onKeyDown={handleOnKeyDown}
            data-testid={`${dataTestId ?? "rich-text-editor"}`}
            style={{ height: "100%" }}
          />
        </Box>
      </>
    );
  }
);
