import { useRef, useState, DragEvent } from "react";
import { Avatar, Grid, Typography } from "@mui/material";
import UploadFileIcon from "@mui/icons-material/UploadFile";
import { colours } from "@/theme/colour";

import { ACCEPTED_FILE_EXTENSIONS } from "@/constants";

const defaultUploadBackgroundColor = "rgba(0, 0, 0, 0.05)";
const onHoverBackgroundColor = "rgba(0, 91, 209, 0.08)";
interface AttachmentUploadBoxProps {
  handleUpload: (file: File) => void;
  customValidation?: (fileList: FileList, validationFailed: (errorMessage: string) => void) => Promise<void>;
  fillHeight?: boolean;
  customFooter?: JSX.Element;
}

export function AttachmentUploadBox({
  handleUpload,
  customValidation,
  fillHeight,
  customFooter
}: AttachmentUploadBoxProps) {
  const [uploadBoxIsHovered, setUploadBoxIsHovered] = useState(false);
  const [errorMessage, setErrorMessage] = useState<string | undefined>();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleDragOver = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    event.stopPropagation();
    setUploadBoxIsHovered(true);
    setErrorMessage(undefined);
  };

  const handleDragLeave = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setUploadBoxIsHovered(false);
    setUploadBoxIsHovered(false);
  };

  const validateFiles = (fileList: FileList | null) =>
    new Promise(async (resolve: (files: File[]) => void, reject: (errorMessage: string) => void) => {
      if (fileList === null) {
        return reject("File list was null");
      }

      if (fileList.length <= 0) {
        return reject("File list was empty");
      }

      await customValidation?.(fileList, reject);

      return resolve(Array.from(fileList));
    });

  const uploadFiles = (files: File[]) => {
    files.forEach((file) => {
      handleUpload(file);
    });

    if (fileInputRef.current) {
      fileInputRef.current.value = "";
    }
  };

  const validateAndUploadFiles = (fileList: FileList | null) => {
    validateFiles(fileList)
      .then(uploadFiles)
      .catch((errorMessage) => setErrorMessage(errorMessage));
  };

  const handleDropUpload = (event: DragEvent<HTMLDivElement>) => {
    event.preventDefault();
    setUploadBoxIsHovered(false);
    validateAndUploadFiles(event.dataTransfer.files);
  };

  return (
    <Grid
      container
      component="div"
      onDragOver={handleDragOver}
      onDragLeave={handleDragLeave}
      onDrop={handleDropUpload}
      border={`1px dashed ${uploadBoxIsHovered ? onHoverBackgroundColor : defaultUploadBackgroundColor}`}
      height={fillHeight ? "100%" : "8rem"}
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
      spacing={3}
      sx={{
        border: errorMessage
          ? "2px solid red" // Solid red border if there's anp error
          : `1px dashed ${uploadBoxIsHovered ? onHoverBackgroundColor : defaultUploadBackgroundColor}`,
        backgroundColor: uploadBoxIsHovered ? onHoverBackgroundColor : defaultUploadBackgroundColor,
        marginTop: "auto",
        borderRadius: 1
      }}
      data-testid="attachment-upload-box"
    >
      <Avatar sx={{ bgcolor: "#1976D21F" }}>
        <UploadFileIcon color={errorMessage ? "error" : "primary"} />
      </Avatar>

      <div style={{ display: "flex", paddingTop: "0.75rem" }}>
        <input
          ref={fileInputRef}
          id="fileInput"
          type="file"
          multiple
          accept={ACCEPTED_FILE_EXTENSIONS.join(", ")}
          style={{ display: "none" }}
          onChange={(event) => {
            validateAndUploadFiles(event.target.files);
          }}
        />
        <label htmlFor="fileInput">
          <Typography
            sx={{
              "&:hover": {
                cursor: "pointer"
              },
              textDecoration: "underline",
              color: colours.hyperlink
            }}
          >
            Click to upload
          </Typography>
        </label>
        <Typography variant="body1">&nbsp;or drag and drop.</Typography>
      </div>
      {errorMessage ? (
        <Typography variant="body1" color="error" data-testid="attachment-upload-box-error">
          {errorMessage}
        </Typography>
      ) : (
        customFooter
      )}
    </Grid>
  );
}
