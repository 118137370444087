import { ComponentType, ForwardRefExoticComponent, JSX } from "react";

import { ARFormSection, ApprovalRequestStatus } from "@/interfaces";
import { useAuthorization, useARContext, usePushNotificationsContext } from "@/context";

interface withARAwareReadOnlyProps {
  readOnly?: boolean;
  name?: string;
}

export function withARAwareReadOnly<P>(
  arFormSection: ARFormSection,
  OriginalComponent: ComponentType<P> | ForwardRefExoticComponent<P>
) {
  function WithARAwareReadOnly(props: P & withARAwareReadOnlyProps & JSX.IntrinsicAttributes) {
    const { userId } = useAuthorization();
    const { isBeingAmended } = usePushNotificationsContext();
    const { approvalRequest } = useARContext();
    let readOnly = false;
    const editableStatuses = [ApprovalRequestStatus.New, ApprovalRequestStatus.Draft];

    if (!approvalRequest || editableStatuses.includes(approvalRequest.approvalRequestStatus)) {
      return <OriginalComponent {...props} />;
    }

    if (approvalRequest.approvalRequestStatus === ApprovalRequestStatus.Submitted) {
      if (userId && userId === approvalRequest.coordinatorId) {
        const editableSections = [ARFormSection.BasicForm];
        readOnly = !editableSections.includes(arFormSection) || isBeingAmended(approvalRequest.id);
      }
    } else {
      readOnly = true;
    }

    if (approvalRequest.approvalRequestStatus === ApprovalRequestStatus.Distributed) {
      readOnly = true;
    }

    return <OriginalComponent {...props} readOnly={props.readOnly || readOnly} />;
  }

  return WithARAwareReadOnly;
}
