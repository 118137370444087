import {
  createRef,
  Dispatch,
  SetStateAction,
  useState,
  MouseEvent,
  useMemo,
  MouseEventHandler,
  useEffect
} from "react";
import {
  Box,
  Button,
  ButtonProps,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Collapse,
  Divider,
  Fade,
  Grid,
  IconButton,
  Menu,
  MenuItem,
  Stack,
  styled,
  SvgIcon,
  Typography
} from "@mui/material";
import { useARContext, useAuthorization, useCollaborationContext } from "@/context";
import { ExpandLess, ExpandMore, MoreVert } from "@mui/icons-material";
import { ApprovalRequestReturnStatus, Commentary } from "@/interfaces";
import { formatHumanizeDate } from "@/utils";
import { Editor } from "@tiptap/core/dist/packages/core/src/Editor";
import { RichTextEditor, RichTextEditorRef } from "@/components/rich-text-editor/RichTextEditor";
import { colours } from "@/theme/colour";
import { useCommentaryDecideReturnRequest } from "@/hooks";
import { CommentaryType } from "@/interfaces/api/responses/Commentary";
import { MatchCaseIcon } from "@/assets/icons";
import { CommentAvatarBadge } from "./CollaborationTab";

interface CommentHeaderTitleProps {
  name: string;
  date: string | undefined;
  identityName: string;
}

interface CommentButtonKebabMenuProps {
  commentary: Commentary;
  setExpanded: Dispatch<SetStateAction<boolean>>;
}

interface CommentaryMenuItemConfig {
  name: string;
  key: string;
  onClick: MouseEventHandler;
}

interface ExpandMoreProps extends ButtonProps {
  expand: boolean;
  visible: boolean;
}

const CommentStack = styled(Stack)`
  padding: 1.5rem 0rem 1.5rem 0rem;
  flex-direction: row;
`;

const CommentButton = styled(Button)`
  width: 3.5rem;
  height: 3.5rem;
`;
const CommentIconButton = styled(IconButton)`
  width: 3.5rem;
  height: 3.5rem;
`;

const UnreadDivider = styled(Divider)({
  width: "95%",
  "& .MuiDivider-wrapper": {
    fontSize: "12px",
    color: "#005BD1"
  },
  "&::before": {
    borderTop: "1px solid #005BD1"
  },
  "&::after": {
    borderTop: "1px solid #005BD1"
  }
});

const ExpandReplies = styled(({ expand, visible, ...other }: ExpandMoreProps) => {
  return (
    <Button
      sx={{
        display: visible ? "flex" : "none",
        float: "left",
        minwidth: "5rem",
        padding: "0rem 0rem 1rem 5rem"
      }}
      variant="text"
      startIcon={!expand ? <ExpandMore /> : <ExpandLess />}
      {...other}
    >
      {!expand ? "Show replies" : "Hide replies"}
    </Button>
  );
})(({ theme }) => ({
  transition: theme.transitions.create("transform", {
    duration: theme.transitions.duration.shortest
  })
}));

function CommentHeaderTitle({ name, date, identityName }: CommentHeaderTitleProps) {
  return (
    <Grid container>
      <Grid item xs={10}>
        <Grid container columnGap={2} sx={{ fontSize: "0.75rem" }}>
          <Grid item>{name}</Grid>
          <Grid item>{date}</Grid>
          <Grid item>{identityName}</Grid>
        </Grid>
      </Grid>
    </Grid>
  );
}

function CommentButtonKebabMenu({ commentary, setExpanded }: CommentButtonKebabMenuProps) {
  const enum COMMENTARY_MENU_ITEMS {
    Edit = "Edit",
    Delete = "Delete",
    Accept = "Accept",
    Decline = "Decline"
  }
  const { userId, isCoordinator, isAdmin } = useAuthorization();
  const { setSelectedCommentary, setFocusCommentary, commentBoxProps, setCommentBoxProps } = useCollaborationContext();
  const { approvalRequest } = useARContext();
  const { mutate: decideReturnRequest } = useCommentaryDecideReturnRequest(commentary.approvalRequestId, commentary.id);
  const isParentComment = commentary.parentCommentaryId === undefined;
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const moreActionToggleOpen = Boolean(anchorEl);
  const handleMoreActionToggleOnClicked = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleMoreActionToggleOnClose = () => {
    setAnchorEl(null);
  };

  const COMMENT_MENU_ITEMS: Record<COMMENTARY_MENU_ITEMS, CommentaryMenuItemConfig> = useMemo(
    () => ({
      [COMMENTARY_MENU_ITEMS.Edit]: {
        name: COMMENTARY_MENU_ITEMS.Edit,
        key: "edit",
        onClick: () => {
          setFocusCommentary(commentary);
          setCommentBoxProps({
            ...commentBoxProps,
            enableUpdateEditor: true,
            enableReplyEditor: false
          });
          handleMoreActionToggleOnClose();
        }
      },
      [COMMENTARY_MENU_ITEMS.Delete]: {
        name: COMMENTARY_MENU_ITEMS.Delete,
        key: "delete",
        onClick: () => {
          setSelectedCommentary(commentary);
          handleMoreActionToggleOnClose();
        }
      },
      [COMMENTARY_MENU_ITEMS.Accept]: {
        name: COMMENTARY_MENU_ITEMS.Accept,
        key: "accept",
        onClick: () => {
          decideReturnRequest(ApprovalRequestReturnStatus.Accepted);
          handleMoreActionToggleOnClose();
        }
      },
      [COMMENTARY_MENU_ITEMS.Decline]: {
        name: COMMENTARY_MENU_ITEMS.Decline,
        key: "decline",
        onClick: () => {
          decideReturnRequest(ApprovalRequestReturnStatus.Declined);
          handleMoreActionToggleOnClose();
        }
      }
    }),
    [
      COMMENTARY_MENU_ITEMS.Edit,
      COMMENTARY_MENU_ITEMS.Delete,
      COMMENTARY_MENU_ITEMS.Accept,
      COMMENTARY_MENU_ITEMS.Decline,
      setFocusCommentary,
      commentary,
      setCommentBoxProps,
      commentBoxProps,
      setSelectedCommentary,
      decideReturnRequest
    ]
  );

  const memoizedMenu: CommentaryMenuItemConfig[] = useMemo(() => {
    if ((userId === commentary.commentatorId && commentary.type) === CommentaryType.Comment) {
      return [COMMENT_MENU_ITEMS[COMMENTARY_MENU_ITEMS.Edit], COMMENT_MENU_ITEMS[COMMENTARY_MENU_ITEMS.Delete]];
    }
    if (
      ((isCoordinator && userId === approvalRequest?.coordinatorId) || isAdmin) &&
      commentary.type === CommentaryType.ReturnRequest &&
      commentary.approvalRequestReturnStatus === ApprovalRequestReturnStatus.Pending
    ) {
      return [COMMENT_MENU_ITEMS[COMMENTARY_MENU_ITEMS.Accept], COMMENT_MENU_ITEMS[COMMENTARY_MENU_ITEMS.Decline]];
    }
    return [];
  }, [
    userId,
    commentary.commentatorId,
    commentary.type,
    commentary.approvalRequestReturnStatus,
    isCoordinator,
    approvalRequest?.coordinatorId,
    isAdmin,
    COMMENT_MENU_ITEMS,
    COMMENTARY_MENU_ITEMS.Edit,
    COMMENTARY_MENU_ITEMS.Delete,
    COMMENTARY_MENU_ITEMS.Accept,
    COMMENTARY_MENU_ITEMS.Decline
  ]);

  return (
    <>
      <Fade in={isParentComment} timeout={250}>
        <CommentButton
          sx={{ display: isParentComment ? "inline" : "none" }}
          onClick={() => {
            setFocusCommentary(commentary);
            setCommentBoxProps({
              ...commentBoxProps,
              enableUpdateEditor: false,
              enableReplyEditor: true
            });
            setExpanded(true);
          }}
          data-testid={`reply-button-${commentary.id}`}
        >
          Reply
        </CommentButton>
      </Fade>
      {memoizedMenu.length > 0 && (
        <>
          <CommentIconButton
            aria-label="more"
            id="long-button"
            aria-controls={moreActionToggleOpen ? "long-menu" : undefined}
            aria-expanded={moreActionToggleOpen ? "true" : undefined}
            aria-haspopup="true"
            data-testid={`more-action-button-${commentary.id}`}
            onClick={handleMoreActionToggleOnClicked}
          >
            <MoreVert />
          </CommentIconButton>
          <Menu
            id="long-menu"
            MenuListProps={{
              "aria-labelledby": "long-button"
            }}
            anchorEl={anchorEl}
            open={moreActionToggleOpen}
            onClose={handleMoreActionToggleOnClose}
          >
            {memoizedMenu.map(({ name, key, onClick }) => {
              return (
                <MenuItem key={key} onClick={onClick} data-testid={`${key}-button-${commentary.id}`}>
                  {name}
                </MenuItem>
              );
            })}
          </Menu>
        </>
      )}
    </>
  );
}

export function Comment({ commentary }: { commentary: Commentary }) {
  const {
    CollaborationCard,
    addComment,
    updateComment,
    handleClearContent,
    handleEscapeKeyPressed,
    resetCommentBox,
    focusCommentary,
    commentBoxProps,
    setCommentBoxProps,
    handleOnBlur,
    unreadReplyComments,
    addTargetsToIntersectionObserver
  } = useCollaborationContext();
  const { userId, name } = useAuthorization();
  const [expanded, setExpanded] = useState<boolean>(false);
  const commentEditorRef = createRef<RichTextEditorRef>();

  useEffect(() => {
    commentEditorRef.current?.reset(commentary.comment);
  }, [commentary.comment]);

  useEffect(() => {
    if (!expanded) return;
    addTargetsToIntersectionObserver();
  }, [expanded]);

  const handleExpandClick = () => {
    setExpanded(!expanded);
  };

  const ReadOnlyCommentBox = (commentary: Commentary) => {
    return (
      <CommentStack>
        <Typography variant="body2" color="text.secondary">
          <RichTextEditor
            ref={commentEditorRef}
            defaultValue={commentary.comment}
            hideMenuBar={true}
            readOnly={true}
            hasMention={true}
            data-testid={`input-comment-editor-${commentary.id}`}
          />
        </Typography>
      </CommentStack>
    );
  };

  const EditingCommentBox = (isNewContent: boolean) => {
    return (
      <CommentStack>
        <Card
          variant="outlined"
          sx={{
            width: "60%",
            height: !commentBoxProps.showMenuBar ? "auto" : "10rem",
            alignItems: "center",
            fontSize: "1rem"
          }}
        >
          <RichTextEditor
            ref={commentEditorRef}
            defaultValue={isNewContent ? "" : focusCommentary?.comment}
            placeholder={isNewContent ? "Add a reply..." : ""}
            hideMenuBar={!commentBoxProps.showMenuBar}
            onKeyDown={(event) => handleEscapeKeyPressed(event, commentEditorRef.current)}
            onBlur={() => handleOnBlur(commentEditorRef.current)}
            onChange={(editor: Editor) =>
              setCommentBoxProps({
                ...commentBoxProps,
                enableSaveButton: isNewContent
                  ? editor.getText().trim().length > 0
                  : editor.getText().trim().length > 0 && editor.getHTML() !== focusCommentary?.comment
              })
            }
            hasMention={true}
            autoFocus={commentBoxProps.enableReplyEditor || commentBoxProps.enableUpdateEditor}
            editorWrapperSX={{ height: !commentBoxProps?.showMenuBar ? "auto" : "10rem", overflow: "auto" }}
          />
        </Card>
        <CommentIconButton
          onClick={() => {
            setCommentBoxProps({ ...commentBoxProps, showMenuBar: !commentBoxProps.showMenuBar });
            commentEditorRef.current?.focus();
          }}
        >
          <SvgIcon component={MatchCaseIcon} inheritViewBox />
        </CommentIconButton>
        <CommentButton
          onClick={() => {
            resetCommentBox();
          }}
        >
          Cancel
        </CommentButton>
        <CommentButton
          onClick={() => {
            isNewContent
              ? addComment(commentEditorRef.current?.getContent(true) ?? "", commentary.id)
              : updateComment(commentary.id, commentEditorRef.current?.getContent(true) ?? "");
            handleClearContent(commentEditorRef.current);
            resetCommentBox();
          }}
          disabled={!commentBoxProps.enableSaveButton}
        >
          Save
        </CommentButton>
      </CommentStack>
    );
  };

  const CommentContent = (commentary: Commentary) => {
    if (focusCommentary?.id === commentary.id && commentBoxProps.enableUpdateEditor) {
      return EditingCommentBox(false);
    }
    return ReadOnlyCommentBox(commentary);
  };

  return (
    <CollaborationCard>
      <CardHeader
        avatar={
          <CommentAvatarBadge
            userId={commentary.commentatorId}
            userName={commentary.commentatorName}
            showBadge={commentary.isUnread && commentary.parentCommentaryId === undefined}
            isUnreadReplyComment={commentary.parentCommentaryId !== undefined && commentary.isUnread}
            notificationId={
              commentary.notificationIds === null || commentary.notificationIds.length === 0
                ? ""
                : commentary.notificationIds![0]
            }
          />
        }
        title={
          <CommentHeaderTitle
            name={commentary.commentatorName}
            date={formatHumanizeDate(commentary.commentDate.toString())}
            identityName=""
          />
        }
      />
      <CardContent sx={{ ml: "-0.5rem" }}>
        <Card
          variant={"elevation"}
          sx={{
            width: "100%",
            boxShadow: "0",
            flexDirection: "row",
            alignItems: "flex-start",
            justifyContent: "space-between",
            ":hover": {
              background: colours.replyHover,
              div: { background: colours.replyHover }
            },
            "&:hover .hover-button": {
              opacity: 1
            }
          }}
        >
          <CardHeader
            sx={{ mt: "-1rem" }}
            action={
              <Box
                className="hover-button"
                sx={{
                  opacity: 0,
                  transition: "opacity 0.3s ease"
                }}
              >
                <CommentButtonKebabMenu commentary={commentary} setExpanded={setExpanded} />
              </Box>
            }
            title={CommentContent(commentary)}
          ></CardHeader>
        </Card>
      </CardContent>
      <CardActions>
        <ExpandReplies
          expand={expanded}
          onClick={handleExpandClick}
          visible={commentary.replyComments.length > 0}
        ></ExpandReplies>
      </CardActions>
      <Collapse in={expanded} timeout="auto" unmountOnExit>
        <>
          <CardContent>
            {commentary.replyComments.map((childComment) => {
              return (
                <>
                  {childComment.parentCommentaryId !== undefined &&
                    unreadReplyComments[childComment.parentCommentaryId] !== undefined &&
                    unreadReplyComments[childComment.parentCommentaryId].length > 0 &&
                    unreadReplyComments[childComment.parentCommentaryId][0] === childComment.id && (
                      <UnreadDivider variant="middle">New messages</UnreadDivider>
                    )}
                  <Comment commentary={childComment} />
                </>
              );
            })}
            {commentBoxProps.enableReplyEditor && focusCommentary?.id === commentary.id && (
              <CollaborationCard>
                <CardHeader
                  avatar={
                    <CommentAvatarBadge
                      userId={userId!}
                      userName={name!}
                      showBadge={false}
                      isUnreadReplyComment={false}
                    />
                  }
                  title={EditingCommentBox(true)}
                ></CardHeader>
              </CollaborationCard>
            )}
          </CardContent>
        </>
      </Collapse>
    </CollaborationCard>
  );
}
