import { Dispatch, SetStateAction, useState, useMemo, useEffect } from "react";
import { BUTTON_LABELS } from "@/constants";
import { Button, Grid, SelectChangeEvent } from "@mui/material";
import { GridToolbarContainer } from "@mui/x-data-grid-pro";
import { Dialog, DialogAction } from "@/components/dialogs";
import { ClearingAllocation, DisciplineAuthorisationCommentValue } from "@/interfaces";
import { useForm } from "react-hook-form";
import { AppFormDateInput, AppFormInput, AppFormSelect } from "@/components/fields";
import { useInstrumentIdList, useRestrictedClearingList } from "@/hooks";
import { nvcpClearingAllocationSchema } from "@/validations";
import { useAuthorization } from "@/context";
import dayjs from "dayjs";
import { isEqual } from "lodash";

interface NVCPClearingAllocationTableToolbarProps {
  setModalDialogAction: Dispatch<SetStateAction<"Add" | "Edit" | "View" | undefined>>;
  modalDialogAction: "Add" | "Edit" | "View" | undefined;
  disabled: boolean;
  rowIsSelected: boolean;
  selectedRow: ClearingAllocation;
  disciplineAuthCommentValue: DisciplineAuthorisationCommentValue;
  clearingAllocations: ClearingAllocation[];
  onUpdate: (updatedValue: DisciplineAuthorisationCommentValue) => void;
  isViewMode?: boolean;
}

export function NVCPClearingAllocationTableToolbar({
  setModalDialogAction,
  modalDialogAction,
  disabled,
  rowIsSelected,
  selectedRow,
  disciplineAuthCommentValue,
  clearingAllocations,
  onUpdate,
  isViewMode
}: NVCPClearingAllocationTableToolbarProps) {
  const { userId, username } = useAuthorization();

  const [selectedReferenceNo, setSelectedReferenceNo] = useState<string>();

  const { data: instrumentIdList } = useInstrumentIdList();
  const { data: restrictedClearingList, refetch: refetchRestrictedClearingList } =
    useRestrictedClearingList(selectedReferenceNo);

  const modalDialogIsOpen = modalDialogAction !== undefined;

  const defaultValues: ClearingAllocation = {
    id: crypto.randomUUID(),
    referenceNo: "",
    clearingAllocation: null,
    noClearingAfterDate: undefined,
    expiryDate: undefined,
    restrictedClearingId: "",
    restrictedClearing: "",
    restrictedClearingAllocation: null,
    termsAndConditions: "",
    createdBy: userId,
    createdByName: username
  };

  const {
    control,
    watch,
    setValue,
    formState: { errors },
    reset
  } = useForm<ClearingAllocation>({ defaultValues });

  const formValues = watch();

  const applyButtonEnabled = useMemo(() => {
    if (modalDialogAction === undefined) {
      return;
    }

    try {
      nvcpClearingAllocationSchema.validateSync(formValues, { abortEarly: false });
    } catch (_error) {
      return false;
    }

    if (modalDialogAction === "Edit") {
      const valuesEqual = isEqual(selectedRow, formValues);

      if (valuesEqual) {
        return false;
      }
    }

    return true;
  }, [modalDialogAction, selectedRow, formValues]);

  const handleDialogOpen = (action: "Add" | "Edit" | "View") => {
    if (action === "Edit" || action === "View") {
      reset(selectedRow);
      setSelectedReferenceNo(selectedRow.referenceNo);
    } else {
      reset(defaultValues);
    }
    setModalDialogAction(action);
  };

  const handleDialogClose = () => {
    setModalDialogAction(undefined);
    setSelectedReferenceNo(undefined);
  };

  const handleAdd = () => {
    const addedFormValues = formValues;

    addedFormValues.created = dayjs().utc().toDate();

    const newClearingAllocation = [addedFormValues, ...clearingAllocations];

    onUpdate({
      ...disciplineAuthCommentValue,
      value: JSON.stringify(newClearingAllocation, (_k, v) => (v === undefined ? null : v))
    });

    handleDialogClose();
  };

  const handleEdit = () => {
    const editedFormValues = formValues;

    editedFormValues.lastModified = dayjs().utc().toDate();
    editedFormValues.lastModifiedBy = userId;
    editedFormValues.lastModifiedByName = username;

    const updatedClearingAllocation = clearingAllocations.map((row) =>
      row.id === editedFormValues.id ? editedFormValues : row
    );

    onUpdate({
      ...disciplineAuthCommentValue,
      value: JSON.stringify(updatedClearingAllocation)
    });

    handleDialogClose();
  };

  const handleDelete = () => {
    const filteredClearingAllocations = clearingAllocations.filter((row) => row.id !== selectedRow.id);

    onUpdate({
      ...disciplineAuthCommentValue,
      value: JSON.stringify(filteredClearingAllocations)
    });
  };

  const getTitleName = () => {
    if (modalDialogAction === "Add") {
      return "Add Clearing Mechanism";
    } else if (modalDialogAction === "View") {
      return `CPS: ${selectedReferenceNo}`;
    } else {
      return "Update Clearing Mechanism";
    }
  };

  const actionButtonList: DialogAction[] = [
    {
      label: BUTTON_LABELS.CANCEL,
      onClick: handleDialogClose,
      disabled: false
    },
    {
      label: BUTTON_LABELS.APPLY,
      disabled: !applyButtonEnabled,
      onClick: modalDialogAction === "Add" ? handleAdd : handleEdit
    }
  ];

  useEffect(() => {
    refetchRestrictedClearingList();
  }, [selectedReferenceNo, refetchRestrictedClearingList]);

  return (
    <GridToolbarContainer sx={{ display: "flex", justifyContent: "flex-end" }}>
      {isViewMode ? (
        <Button
          variant="text"
          color="secondary"
          data-testid="nvcp-table-view-button"
          disabled={!rowIsSelected}
          onClick={() => handleDialogOpen("View")}
        >
          View
        </Button>
      ) : (
        <>
          <Button
            variant="text"
            color="secondary"
            data-testid="nvcp-table-add-button"
            disabled={disabled}
            onClick={() => handleDialogOpen("Add")}
          >
            Add
          </Button>
          <Button
            variant="text"
            color="secondary"
            data-testid="nvcp-table-edit-button"
            disabled={!rowIsSelected || disabled}
            onClick={() => handleDialogOpen("Edit")}
          >
            Edit
          </Button>
          <Button
            variant="text"
            color="secondary"
            data-testid="nvcp-table-delete-button"
            disabled={!rowIsSelected || disabled}
            onClick={handleDelete}
          >
            Delete
          </Button>
        </>
      )}

      <Dialog
        fullWidth={true}
        maxWidth={"lg"}
        data-testid={"clearing-allocation-modal"}
        open={modalDialogIsOpen}
        onClose={handleDialogClose}
        title={getTitleName()}
        actions={modalDialogAction !== "View" ? actionButtonList : []}
        showCloseIcon={isViewMode}
      >
        <Grid container spacing={{ xs: 1, sm: 2 }} columns={12} sx={{ pt: 1 }}>
          <Grid item xs={6}>
            <AppFormSelect
              name={"referenceNo"}
              control={control}
              error={errors.id}
              data-testid="select-clearing-instrument-referenceNo"
              showError={false}
              label={"Clearing instrument"}
              readOnly={isViewMode}
              options={instrumentIdList}
              isOptional={true}
              onChangeHandler={(event) => {
                const value = (event as SelectChangeEvent).target.value;
                setSelectedReferenceNo(value);
                setValue("restrictedClearingAllocation", defaultValues.restrictedClearingAllocation);
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <AppFormInput
              control={control}
              label={"Clearing allocation (ha)"}
              name={"clearingAllocation"}
              error={errors.clearingAllocation}
              data-testid="input-clearing-allocation"
              showError={false}
              type="number"
              disabled={selectedReferenceNo === undefined}
              readOnly={isViewMode}
            />
          </Grid>
          <Grid item xs={6}>
            <AppFormDateInput
              name={"noClearingAfterDate"}
              control={control}
              error={errors.noClearingAfterDate}
              data-testid="date-no-clearing-after"
              showError={false}
              label={"No clearing after"}
              disabled={selectedReferenceNo === undefined}
              readOnly={isViewMode}
            />
          </Grid>
          <Grid item xs={6}>
            <AppFormDateInput
              name={"expiryDate"}
              control={control}
              error={errors.expiryDate}
              data-testid="date-expiry-date"
              showError={false}
              label={"Expiry"}
              disabled={selectedReferenceNo === undefined}
              readOnly={isViewMode}
            />
          </Grid>
          <Grid item xs={6}>
            <AppFormSelect
              name={"restrictedClearingId"}
              control={control}
              error={errors.restrictedClearingId}
              data-testid="select-restricted-clearing-id"
              label={"Restricted clearing"}
              options={restrictedClearingList}
              isOptional={true}
              showError={false}
              onChangeHandler={(event) => {
                setValue("restrictedClearingAllocation", defaultValues.restrictedClearingAllocation);
                const value = (event as SelectChangeEvent).target.value;
                setValue("restrictedClearing", restrictedClearingList.filter((row) => row.id === value)[0].value);
              }}
              disabled={selectedReferenceNo === undefined}
              readOnly={isViewMode}
              clearable={true}
            />
          </Grid>
          <Grid item xs={6}>
            <AppFormInput
              control={control}
              label={"Restricted clearing allocation (ha)"}
              name={"restrictedClearingAllocation"}
              error={errors.restrictedClearingAllocation}
              data-testid="input-restricted-clearing-allocation"
              showError={false}
              type="number"
              disabled={selectedReferenceNo === undefined}
              readOnly={isViewMode}
            />
          </Grid>
          <Grid item xs={12}>
            <AppFormInput
              control={control}
              label={"Terms and Conditions"}
              name={"termsAndConditions"}
              error={errors.termsAndConditions}
              data-testid="input-terms-and-conditions"
              showError={false}
              multiline={true}
              rows={5}
              disabled={selectedReferenceNo === undefined}
              readOnly={isViewMode}
            />
          </Grid>
        </Grid>
      </Dialog>
    </GridToolbarContainer>
  );
}
