import { RouteObject, createBrowserRouter } from "react-router-dom";

import { APPROVAL_REQUEST_PATH, PATH } from "@/constants";
import { BaseLayout } from "@/layouts";
import { AdminSettingsRoutes } from "./adminSettingsRoutes";
import { DetectedInterceptRoutes } from "./detectedInterceptRoutes";
import { ApprovalRequestTabRoutes } from "./approvalRequestRoutes";
import { HomeRoutes } from "./homePageRoutes";
import { MyRequestsRoutes } from "./myRequestsRoutes";
import { PermitPrintPreview } from "@/components/my-requests/permit/PermitPrintPreview";
import { DiffViewer } from "@/components/diff-viewers/DiffViewer";
import { RichTextDiffEditor } from "@/components/diff-viewers/RichTextDiffViewer";

const AppRoutes: RouteObject[] = [
  {
    path: PATH.BASE,
    element: <BaseLayout />,
    children: [HomeRoutes, AdminSettingsRoutes, DetectedInterceptRoutes, MyRequestsRoutes, ApprovalRequestTabRoutes]
  },
  {
    path: APPROVAL_REQUEST_PATH.PERMIT_PREVIEW,
    element: <PermitPrintPreview />
  },
  /* TODO: Remove the below when final implementation is complete as part of description summary of change. */
  {
    path: "/diff",
    element: <DiffViewer />
  },
  {
    path: "/rte-diff",
    element: <RichTextDiffEditor />
  }
];

export const router = createBrowserRouter(AppRoutes);
