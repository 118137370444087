import { AppFormInput } from "@/components/fields";
import { ARDiscipline, ARReturnPermitFormFields, IOption, ReturnDraftPermitRecipient } from "@/interfaces";
import { FormControl, Grid, Typography } from "@mui/material";
import { Dialog } from "@/components/dialogs";
import { Fragment, useMemo } from "react";
import { useForm } from "react-hook-form";
import { AppFormCheckbox } from "@/components/fields/AppFormCheckbox";
import { AppFormRadio } from "@/components/fields/AppFormRadio";
import { useReturnDraftPermit } from "@/hooks/api/ApprovalRequestHooks";
import { useSetAtom } from "jotai";
import { snackBarAtom } from "@/stores";
import { AR_FORM } from "@/constants";

const PERMIT_RECIPIENT_OPTIONS: IOption[] = [
  {
    value: "Discipline SMEs",
    id: ReturnDraftPermitRecipient.SME.toString()
  },
  {
    value: "Requestor",
    id: ReturnDraftPermitRecipient.Requestor.toString()
  }
];

interface ReturnPermitFormProps {
  isReturn: boolean;
  approvalRequestId: string;
  approvalRequestDisciplines: ARDiscipline[];
  dismissModal: () => void;
}

export function ReturnPermitForm({
  isReturn,
  dismissModal,
  approvalRequestId,
  approvalRequestDisciplines
}: ReturnPermitFormProps) {
  const setSnackBar = useSetAtom(snackBarAtom);

  const { mutate: returnDraftPermit } = useReturnDraftPermit();

  const {
    control,
    getValues,
    watch,
    formState: { errors },
    reset
  } = useForm<ARReturnPermitFormFields>({
    defaultValues: {
      returnTo: 1,
      disciplineToUnauthorise: [],
      comments: ""
    }
  });

  const returnPermitFormValues = watch(["returnTo", "disciplineToUnauthorise", "comments"]);

  const returnButtonDisabled = useMemo(() => {
    const [returnTo, disciplineToUnauthorise, comments] = returnPermitFormValues;

    if (comments.replaceAll(/\s/g, "").length === 0) {
      return true;
    }

    if (returnTo.toString() === ReturnDraftPermitRecipient.SME.toString() && disciplineToUnauthorise.length === 0) {
      return true;
    }

    return false;
  }, [returnPermitFormValues]);

  const compulsoryIndicator = getValues("returnTo").toString() === ReturnDraftPermitRecipient.SME.toString() ? "*" : "";

  const handleDialogClose = () => {
    dismissModal();
    reset();
  };

  const handleReturnAR = () => {
    const formValues = getValues();

    returnDraftPermit(
      {
        approvalRequestId,
        returnRecipient: formValues.returnTo,
        unauthorisedDisciplines: formValues.disciplineToUnauthorise,
        comments: formValues.comments
      },
      {
        onSuccess: () => {
          setSnackBar({
            message: AR_FORM.RETURN_AR_SUCCESSFUL,
            open: true
          });
          handleDialogClose();
        }
      }
    );
  };

  return (
    <Dialog
      open={isReturn}
      onClose={handleDialogClose}
      data-testid="return-permit-dialog"
      title={"Return Permit"}
      actions={[
        {
          label: "Cancel",
          onClick: handleDialogClose
        },
        {
          label: "Return",
          disabled: returnButtonDisabled,
          onClick: handleReturnAR,
          isFormSubmit: true
        }
      ]}
    >
      <Grid container>
        <Fragment>
          <FormControl sx={{ width: "30rem", maxHeight: "35rem" }}>
            <Typography gutterBottom color={"grey"}>
              Return to:
            </Typography>
            <AppFormRadio
              name={"returnTo"}
              control={control}
              options={PERMIT_RECIPIENT_OPTIONS}
              error={errors.returnTo}
              data-testid="radioInput"
            />
            <Typography gutterBottom color={"grey"}>
              {`Which disciplines require reauthorisation? ${compulsoryIndicator}`}
            </Typography>
            <AppFormCheckbox
              name={"disciplineToUnauthorise"}
              control={control}
              options={approvalRequestDisciplines.map((discipline) => ({ id: discipline.id, value: discipline.name }))}
              data-testid="checkBoxInput"
            />
            <AppFormInput
              margin={"normal"}
              control={control}
              label={"Comments"}
              name={"comments"}
              required={true}
              error={errors.comments}
              data-testid="commentsInput"
              showError={true}
              multiline={true}
              rows={3}
              maxLength={500}
            />
          </FormControl>
        </Fragment>
      </Grid>
    </Dialog>
  );
}
