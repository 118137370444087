import { useRef, useState } from "react";
import { ArcgisMap, ArcgisZoom } from "@arcgis/map-components-react";
import { Grid } from "@mui/material";
// import defineCustomElements to register custom elements with the custom elements registry
import { defineCustomElements } from "@arcgis/map-components/dist/loader";
import { ArcgisMapCustomEvent } from "@arcgis/map-components/dist/types/components";
import Expand from "@arcgis/core/widgets/Expand";
import "@arcgis/core/assets/esri/css/main.css";

import { MapToolbox } from "./MapToolbox";
import { createRoot } from "react-dom/client";
defineCustomElements(window, { resourcesUrl: "https://js.arcgis.com/map-components/4.30/assets" });

import { useARContext } from "@/context";
import { ACCEPTED_FILE_EXTENSIONS } from "@/constants";
import { AddApprovalRequestMap } from "@/interfaces";
import { useAddApprovalRequestMap } from "@/hooks";

const { REACT_APP_ARCGIS_WEBMAP_ITEM_ID } = import.meta.env;

interface MapRenderProps {
  mapLoaded: boolean;
  setMapLoaded: React.Dispatch<React.SetStateAction<boolean>>;
}

export function MapRender({ mapLoaded, setMapLoaded }: MapRenderProps) {
  const [fullScreen, setFullScreen] = useState(false);
  const { approvalRequest, approvalRequestId, setUploadInProgress } = useARContext();
  const fileInputRef = useRef<HTMLInputElement>(null);

  const setMapScale = (event: ArcgisMapCustomEvent<void>) => {
    const view = event.target.view;
    if (view) {
      // Set the minimum and maximum scale
      view.constraints = {
        minScale: 9000000,
        maxScale: 35
      };
    }
  };

  const addMapToolboxPanel = (event: ArcgisMapCustomEvent<void>) => {
    const view = event.target.view;
    if (view) {
      const mapPanel = document.createElement("div");
      const rootPanel = createRoot(mapPanel);

      const expand = new Expand({
        view: view,
        content: mapPanel,
        expanded: true
      });

      view.ui.add(expand, "top-left");
      rootPanel.render(
        <MapToolbox
          setFullScreen={setFullScreen}
          mapView={view}
          approvalRequestStatus={approvalRequest.approvalRequestStatus}
        />
      );
    }
  };

  const { mutate: updateMapMutation } = useAddApprovalRequestMap(approvalRequestId);

  const uploadFile = async (fileToUpload: File) => {
    setUploadInProgress(true);

    const fileData = new FormData();
    fileData.append("file", fileToUpload);

    const addMapCommand: AddApprovalRequestMap = {
      approvalRequestId,
      fileData
    };

    updateMapMutation(addMapCommand, {
      onSettled: () => {
        setUploadInProgress(false);
      }
    });
  };

  return (
    <Grid
      className={fullScreen ? "ar-map-fullscreen" : ""}
      data-testid="map-render-display"
      visibility={mapLoaded ? "visible" : "hidden"}
      sx={{ flexDirection: "column", flexGrow: 1, height: "100%" }}
      style={{ margin: "0 auto" }}
    >
      <input
        ref={fileInputRef}
        id="fileInput"
        type="file"
        accept={ACCEPTED_FILE_EXTENSIONS.join(", ")}
        style={{ display: "none" }}
        onChange={(event) => {
          const files = event.target.files;
          if (files) {
            uploadFile(files[0]);
          }
        }}
      />
      <ArcgisMap
        itemId={REACT_APP_ARCGIS_WEBMAP_ITEM_ID}
        onArcgisViewReadyChange={(event) => {
          setMapLoaded(true);
          setMapScale(event);
          addMapToolboxPanel(event);
        }}
      >
        <ArcgisZoom position="bottom-right" layout="vertical"></ArcgisZoom>
      </ArcgisMap>
    </Grid>
  );
}
