import { useEffect, useState } from "react";
import axios, { CancelTokenSource } from "axios";
import { Grid, Typography } from "@mui/material";

import { ACCEPTED_FILE_EXTENSIONS, ATTACHMENT_COUNT_QUERY_KEY, ATTACHMENT_ERRORS } from "@/constants";
import { Attachment, AttachmentSet, AttachmentUploadStatus, FileValidationResult } from "@/interfaces";
import { deleteAttachment, getAttachments, saveAttachment } from "@/services";
import { useARContext } from "@/context";
import { UploadedAttachmentsDisplay, AttachmentUploadBox } from "@/features/my-requests/components";
import { useQueryClient } from "@tanstack/react-query";

export const AttachmentUpload = () => {
  const queryClient = useQueryClient();

  const { approvalRequestId, setUploadInProgress } = useARContext();

  const [attachments, setAttachments] = useState<AttachmentSet>({});

  useEffect(() => {
    getAttachments(approvalRequestId).then((attachments) => {
      if (attachments.length <= 0) return;
      setAttachments(() => {
        const allAttachments: AttachmentSet = {};
        attachments.forEach((attachment) => {
          const dotIndex = attachment.fileName.lastIndexOf(".");
          const fileName = attachment.fileName.substring(0, dotIndex);
          const fileExtension = attachment.fileName.substring(dotIndex);
          allAttachments[attachment.id] = {
            ...attachment,
            fileName: fileName,
            contentType: fileExtension,
            uploadStatus: AttachmentUploadStatus.Successful,
            cancellationToken: null,
            dateChanged: new Date(),
            errorMessage: null,
            createdBy: attachment.createdBy
          };
        });

        return allAttachments;
      });
    });
  }, [approvalRequestId]);

  useEffect(() => {
    setUploadInProgress(() => {
      const result = Object.values(attachments).some((x) => x.uploadStatus === AttachmentUploadStatus.Processing);
      return result;
    });
  }, [attachments, setUploadInProgress]);

  const handleDeleteUpload = (attachmentId: string) => {
    if (approvalRequestId === undefined) return;

    const attachment = attachments[attachmentId];
    if (attachment.uploadStatus === AttachmentUploadStatus.Failed) {
      setAttachments((existingAttachments) => {
        delete existingAttachments[attachmentId];
        return {
          ...existingAttachments
        };
      });
    } else {
      deleteAttachment(approvalRequestId, attachmentId)
        .then(() => {
          setAttachments((existingAttachments) => {
            delete existingAttachments[attachmentId];
            return {
              ...existingAttachments
            };
          });
        })
        .catch(() => {
          setAttachments((existingAttachments) => ({
            [attachmentId]: {
              ...existingAttachments[attachmentId],
              errorMessage: ATTACHMENT_ERRORS.DELETE_FAILED
            },
            ...existingAttachments
          }));
        });
    }
  };

  const handleDeleteClick = (attachmentId: string) => {
    //TODO: Check for File Upload Owner here... [ADO-12455]
    const attachment = attachments[attachmentId];
    if (attachment.cancellationToken !== null) {
      attachment.cancellationToken.cancel();
      setAttachments((existingAttachments) => {
        delete existingAttachments[attachmentId];
        return {
          ...existingAttachments
        };
      });
    } else {
      handleDeleteUpload(attachmentId);
    }
  };

  const fileIsUnique = (file: File) => {
    let attachmentIsUnique = true;
    Object.entries(attachments).forEach(([id, value]) => {
      if (value.fileName + value.contentType === file.name) {
        if (value.uploadStatus === AttachmentUploadStatus.Failed) {
          setAttachments((existingAttachments) => {
            delete existingAttachments[id];
            return {
              ...existingAttachments
            };
          });
        } else {
          attachmentIsUnique = false;
        }
      }
    });
    return attachmentIsUnique;
  };

  const fileTypeIsValid = (fileName: string) => {
    const fileExtension = `.${fileName.split(".").pop()}`;
    return ACCEPTED_FILE_EXTENSIONS.includes(fileExtension.toLocaleLowerCase());
  };

  const isValidFile = (file: File) => {
    const validationResult: FileValidationResult = {
      isValid: true,
      errorMessage: null
    };

    if (!fileTypeIsValid(file.name)) {
      validationResult.isValid = false;
      validationResult.errorMessage = ATTACHMENT_ERRORS.INVALID_FILE_TYPE;
    } else if (!fileIsUnique(file)) {
      validationResult.isValid = false;
      validationResult.errorMessage = ATTACHMENT_ERRORS.DUPLICATE_FILE;
    }
    return validationResult;
  };

  const uploadFile = async (fileToUpload: File) => {
    const uploadStatus = AttachmentUploadStatus.Processing;
    const cancellationToken = axios.CancelToken.source();
    const attachmentToUpload = convertFileToAttachment(fileToUpload, uploadStatus, cancellationToken);
    const attachmentId = crypto.randomUUID();

    const fileValidationResult = isValidFile(fileToUpload);

    if (!fileValidationResult.isValid) {
      setAttachments((existingAttachments) => ({
        ...existingAttachments,
        [attachmentId]: {
          ...attachmentToUpload,
          uploadStatus: AttachmentUploadStatus.Failed,
          errorMessage: fileValidationResult.errorMessage,
          dateChanged: new Date()
        }
      }));
      return;
    }

    setAttachments((existingAttachments) => ({
      ...existingAttachments,
      [attachmentId]: attachmentToUpload
    }));

    const fileData = new FormData();
    fileData.append("file", fileToUpload);

    saveAttachment(approvalRequestId, fileData, attachmentToUpload.cancellationToken?.token)
      .then((response) => {
        setAttachments((existingAttachments) => {
          delete existingAttachments[attachmentId];
          return {
            ...existingAttachments,
            [response.data.id]: {
              ...attachmentToUpload,
              uploadStatus: AttachmentUploadStatus.Successful,
              cancellationToken: null,
              dateChanged: new Date()
            }
          };
        });
      })
      .catch((error) => {
        const errorMessage = "";
        if (axios.isCancel(error)) {
          setAttachments((existingAttachments) => {
            delete existingAttachments[attachmentId];
            return {
              ...existingAttachments
            };
          });
        } else {
          setAttachments((existingAttachments) => ({
            ...existingAttachments,
            [attachmentId]: {
              ...existingAttachments[attachmentId],
              uploadStatus: AttachmentUploadStatus.Failed,
              cancellationToken: null,
              errorMessage: errorMessage !== "" ? errorMessage : null,
              dateChanged: new Date()
            }
          }));
        }
      })
      .finally(() => {
        queryClient.invalidateQueries([ATTACHMENT_COUNT_QUERY_KEY, approvalRequestId]);
      });
  };

  return (
    <Grid container rowSpacing={2}>
      <Grid item xs={12}>
        <Typography variant="h6">Attachments</Typography>
      </Grid>
      <Grid item xs={8} style={{ margin: "0 auto" }}>
        <AttachmentUploadBox handleUpload={uploadFile} />
      </Grid>
      <Grid item xs={11} style={{ margin: "0 auto" }}>
        <UploadedAttachmentsDisplay
          handleDeleteClick={handleDeleteClick}
          attachmentSet={attachments}
          approvalRequestId={approvalRequestId}
        />
      </Grid>
    </Grid>
  );

  function convertFileToAttachment(
    file: File,
    status: AttachmentUploadStatus,
    cancellationToken: CancelTokenSource | null
  ): Attachment {
    const dotIndex = file.name.lastIndexOf(".");
    const fileName = file.name.substring(0, dotIndex);
    const fileExtension = file.name.substring(dotIndex);
    const newAttachment: Attachment = {
      fileName: fileName,
      contentType: fileExtension,
      fileSize: file.size,
      uploadStatus: status,
      cancellationToken: cancellationToken,
      errorMessage: null,
      dateChanged: new Date(),
      createdBy: ""
    };

    return newAttachment;
  }
};
