import { Fragment, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import { AppBar, AppBarProps, Chip, Divider, IconButton, styled, Toolbar, Typography } from "@mui/material";
import ArrowLeftIcon from "@mui/icons-material/ArrowBackIos";
import { useResetAtom } from "jotai/utils";
import { Schema, ValidationError } from "yup";
import { useSetAtom } from "jotai";
import { useFormContext } from "react-hook-form";

import {
  AR_DISTRIBUTED_ERROR_BANNER,
  AR_FORM,
  AR_MODIFY_ERROR_BANNER,
  AR_SAVE_ERROR_BANNER,
  AR_STATUSES,
  AR_UPLOAD_IN_PROGRESS_ERROR_BANNER,
  AR_SUBMIT_ERROR_BANNER,
  PATH,
  UNSAVED_CHANGES_MODAL,
  UPLOAD_IN_PROGRESS_MODAL,
  BUTTON_LABELS,
  AR_UNEXPECTED_ERROR_BANNER,
  FORM_HELPER_ERROR_CLASS,
  AR_DISTRIBUTED_DISCIPLINE_SME_ERROR_BANNER,
  AR_PERMIT_EXPIRY_DATE_ERROR_BANNER,
  AR_PERMIT_EXPIRY_DATE_ERROR_WITH_NVCP_BANNER,
  AR_SUBMIT_MAP_ERROR_BANNER
} from "@/constants";
import {
  ApprovalRequestBase,
  ApprovalRequestStatus,
  AR_ACTION_BUTTONS,
  ARActionButtonComponentName,
  ARActionButtonProps,
  ARFormValues,
  AlertBannerContent,
  UpdateApprovalRequest,
  ApprovalRequestDisciplineStatus,
  APIException,
  DistributionType,
  ARBasicDetails
} from "@/interfaces";
import { filterAndMapToQAAnswerItem } from "@/utils";
import { saveARSchema, submitARSchema, noValidationSchema, arBasicDetailsSubmitSchema } from "@/validations";
import { useARContext, useAuthorization, usePushNotificationsContext } from "@/context";
import { navigationBlockerAtom, snackBarAtom } from "@/stores";
import { ARHeaderDialog } from "@/components/dialogs";
import { ARHeaderButtonGroup } from "./ARHeaderButtonGroup";
import { PartialRecord } from "@/types";
import { useDistributeApprovalRequest } from "@/hooks/api/ApprovalRequestHooks";
import {
  AmendPermitForm,
  RedistributeARForm,
  ReturnARForm,
  ReturnPermitForm,
  RedistributeAmendARForm
} from "./dialogs";

const ARHeaderAppBar = styled(AppBar)<AppBarProps>(({ theme }) => ({
  height: "4rem",
  zIndex: theme.zIndex.drawer + 2,
  background: "white",
  color: "black",
  top: "4rem",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen
  })
}));

export function ARHeader() {
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { approvalRequestDisciplineId } = useParams();

  const { isGuest, isCoordinator, isSME, isEndorser, isRequestor, userId } = useAuthorization();
  const { isBeingAmended } = usePushNotificationsContext();
  const {
    approvalRequestId,
    approvalRequest,
    approvalRequestType,
    isARDirty,
    saveApprovalRequest,
    deleteApprovalRequest,
    appendAlertBanner,
    resetAlertBanner,
    resetDisciplineTab,
    userIsSMEOfDisciplineOrAdmin,
    userIsSMEOfDiscipline,
    saveDisciplineTabs,
    approvalRequestDisciplines,
    authoriseDisciplineHandler,
    allAuthorisationComments,
    userIsSMEOfAr,
    allQuestionsAnswered,
    setAllQuestionsAnswered,
    uploadInProgress
  } = useARContext();
  const { clearErrors, getValues, reset, setError } = useFormContext<ARFormValues>();

  const { mutate: distributeApprovalRequest } = useDistributeApprovalRequest(approvalRequestId);

  /* Dialog States */
  const [modifyDialogOpen, setModifyDialogOpen] = useState(false);
  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [returnDialogOpen, setReturnDialogOpen] = useState(false);
  const [permitReturnDialogOpen, setPermitReturnDialogOpen] = useState(false);
  const [permitAmendDialogOpen, setPermitAmendDialogOpen] = useState(false);
  const [redistributeDialogOpen, setRedistributeDialogOpen] = useState(false);
  const [redistributeAmendDialogOpen, setRedistributeAmendDialogOpen] = useState(false);

  const [actionInProgress, setActionInProgress] = useState(false);
  const [isError, setIsError] = useState(false);

  const setNavigationBlocker = useSetAtom(navigationBlockerAtom);
  const resetNavigationBlockerAtom = useResetAtom(navigationBlockerAtom);
  const setSnackBar = useSetAtom(snackBarAtom);

  const arBasicDetailsValues = useRef<Partial<ARBasicDetails>>({});

  const isAtDetailsTab = useMemo(() => pathname.endsWith("details"), [pathname]);

  const isARDetailsUpdatable = useMemo(
    () =>
      ([ApprovalRequestStatus.Draft, ApprovalRequestStatus.New].includes(approvalRequest!.approvalRequestStatus) &&
        !isSME) ||
      (isCoordinator && approvalRequest!.approvalRequestStatus === ApprovalRequestStatus.Submitted),
    [approvalRequest, isCoordinator, isSME]
  );

  const isDistributed = useMemo(
    () => approvalRequest.approvalRequestStatus === ApprovalRequestStatus.Distributed,
    [approvalRequest]
  );

  const showValidationErrors = useCallback(
    ({ inner }: ValidationError, alertBannerContent: AlertBannerContent) => {
      if (inner.length === 1 && inner[0].path === "mapUploaded") {
        appendAlertBanner(AR_SUBMIT_MAP_ERROR_BANNER);
      } else {
        appendAlertBanner(alertBannerContent);
        inner.forEach(({ path, message }) => {
          setError(path as keyof ARFormValues, { message });
          setIsError(true);
        });
      }
    },
    [appendAlertBanner, setError]
  );

  const validateAndUpdateAR = useCallback(
    (
      schema: Schema,
      status: ApprovalRequestStatus,
      snackBarMessage: string,
      validationAlertBannerConfig: AlertBannerContent,
      onUpdate?: (response: ApprovalRequestBase) => void,
      onValidationFail?: () => void
    ) => {
      if (uploadInProgress) {
        appendAlertBanner(AR_UPLOAD_IN_PROGRESS_ERROR_BANNER);
        return;
      }

      clearErrors();
      resetAlertBanner([AR_SUBMIT_MAP_ERROR_BANNER.id]);
      setIsError(false);

      const formValues = getValues();
      const request: UpdateApprovalRequest = {
        ...approvalRequest,
        approvalRequestStatus: status,
        title: formValues.title,
        hubId: formValues.hubId,
        siteId: formValues.siteId,
        businessUnitId: formValues.businessUnitId,
        projectId: formValues.projectId,
        costCode: formValues.costCode,
        requiredByDate: formValues.requiredByDate,
        description: formValues.description,
        approvalRequestAnswers: filterAndMapToQAAnswerItem(formValues.approvalRequestAnswers),
        approvalRequestWorkCategories: formValues.approvalRequestWorkCategories,
        extendBy: formValues.extendBy
      };

      schema
        .validate(
          {
            ...request,
            allQuestionsAnswered: allQuestionsAnswered,
            approvalRequestAnswers: formValues.approvalRequestAnswers,
            mapUploaded: formValues.mapUploaded
          },
          { abortEarly: false }
        )
        .then(() => {
          saveApprovalRequest(
            request,
            (updatedAR: ApprovalRequestBase) => {
              // TODO: Not working... this is needed so that when user click save, the comparison card should re-fetch new value
              setSnackBar({
                message: snackBarMessage,
                open: true
              });
              onUpdate?.(updatedAR);
              resetNavigationBlockerAtom();
            },
            (error: unknown) => {
              const errorDetails = error as APIException;
              if (errorDetails && errorDetails["UnprocessableEntity"] !== undefined) {
                switch (request.approvalRequestStatus) {
                  case ApprovalRequestStatus.PermitIssued: {
                    const unprocessableEntityErrors = errorDetails["UnprocessableEntity"];

                    const disciplineListWithoutNVCP = unprocessableEntityErrors
                      .filter((item) => item.propertyName !== "NVCP")
                      .map((item) => item.propertyName);
                    const disciplineToDisplay = disciplineListWithoutNVCP.join(", ");

                    if (disciplineListWithoutNVCP.length !== 0) {
                      appendAlertBanner(AR_PERMIT_EXPIRY_DATE_ERROR_BANNER(disciplineToDisplay));
                    }
                    if (unprocessableEntityErrors.length !== disciplineListWithoutNVCP.length) {
                      appendAlertBanner(AR_PERMIT_EXPIRY_DATE_ERROR_WITH_NVCP_BANNER);
                    }
                    break;
                  }
                }
              } else {
                appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
              }
            }
          );
        })
        .catch((error: ValidationError) => {
          showValidationErrors(error, validationAlertBannerConfig);
          onValidationFail?.();
        })
        .finally(() => {
          setActionInProgress(false);
        });
    },
    [
      uploadInProgress,
      clearErrors,
      resetAlertBanner,
      getValues,
      approvalRequest,
      allQuestionsAnswered,
      appendAlertBanner,
      saveApprovalRequest,
      setSnackBar,
      resetNavigationBlockerAtom,
      showValidationErrors
    ]
  );

  const validateARBasicDetails = useCallback((): Promise<Partial<ARBasicDetails>> => {
    const formValues = getValues();

    const approvalRequestBasicDetails: Partial<ARBasicDetails> = {
      title: formValues.title,
      hubId: formValues.hubId,
      siteId: formValues.siteId,
      businessUnitId: formValues.businessUnitId,
      projectId: formValues.projectId,
      costCode: formValues.costCode,
      description: formValues.description,
      requiredByDate: formValues.requiredByDate
    };

    return arBasicDetailsSubmitSchema
      .validate(approvalRequestBasicDetails, { abortEarly: false })
      .then(() => approvalRequestBasicDetails);
  }, [getValues]);

  const onDistributeSuccess = useCallback(() => {
    setSnackBar({ open: true, message: AR_FORM.DISTRIBUTE_AR_SUCCESSFUL });
    reset({ ...arBasicDetailsValues.current });
    resetNavigationBlockerAtom();
  }, [reset, resetNavigationBlockerAtom, setSnackBar]);

  const onDistributeError = useCallback(
    (error: unknown) => {
      const errorDetails = error as APIException;
      if (errorDetails && errorDetails["UnprocessableEntity"] !== undefined) {
        const erroredDisciplines = errorDetails["UnprocessableEntity"].map((error) => error.propertyName).join(", ");
        appendAlertBanner(AR_DISTRIBUTED_DISCIPLINE_SME_ERROR_BANNER(erroredDisciplines, approvalRequest?.hub));
      } else {
        appendAlertBanner(AR_UNEXPECTED_ERROR_BANNER);
      }
    },
    [appendAlertBanner, approvalRequest?.hub]
  );

  const distributeOnClickHandler = useCallback(async () => {
    setActionInProgress(true);

    validateARBasicDetails()
      .then((arBasicDetails) => {
        arBasicDetailsValues.current = arBasicDetails;

        if (approvalRequestDisciplines.some((d) => d.status === ApprovalRequestDisciplineStatus.Authorised)) {
          setRedistributeDialogOpen(true);
        } else if (approvalRequest.cycle !== undefined) {
          setRedistributeAmendDialogOpen(true);
        } else {
          distributeApprovalRequest(
            {
              distributionType: DistributionType.Default,
              ...arBasicDetails
            },
            { onSuccess: onDistributeSuccess, onError: onDistributeError }
          );
        }
      })
      .catch((error: ValidationError) => {
        showValidationErrors(error, AR_DISTRIBUTED_ERROR_BANNER);
      })
      .finally(() => {
        setActionInProgress(false);
      });
  }, [
    approvalRequest.cycle,
    approvalRequestDisciplines,
    distributeApprovalRequest,
    onDistributeError,
    onDistributeSuccess,
    showValidationErrors,
    validateARBasicDetails
  ]);

  const authorisedOnClickHandler = useCallback(() => {
    setActionInProgress(true);

    validateAndUpdateAR(
      noValidationSchema,
      ApprovalRequestStatus.PermitIssued,
      AR_FORM.PERMIT_AUTHORISED_SUCCESSFUL,
      AR_DISTRIBUTED_ERROR_BANNER
    );
  }, [validateAndUpdateAR]);

  const saveOnClickHandler = useCallback(
    (onSuccess?: () => void, onFail?: () => void) => {
      setActionInProgress(true);
      if (isARDetailsUpdatable) {
        const isARSchemaInUsed = approvalRequest.approvalRequestStatus < ApprovalRequestStatus.Submitted;
        validateAndUpdateAR(
          isARSchemaInUsed ? saveARSchema() : submitARSchema(isCoordinator),
          approvalRequest.approvalRequestStatus === ApprovalRequestStatus.New
            ? ApprovalRequestStatus.Draft
            : approvalRequest.approvalRequestStatus,
          AR_FORM.SAVE_AR_SUCCESSFUL,
          AR_SAVE_ERROR_BANNER,
          onSuccess,
          onFail
        );
      }

      if (isDistributed || userIsSMEOfDiscipline) {
        saveDisciplineTabs(() => {
          onSuccess?.();
          resetNavigationBlockerAtom();
        }, onFail);
      }
      setActionInProgress(false);
    },
    [
      isARDetailsUpdatable,
      isDistributed,
      userIsSMEOfDiscipline,
      approvalRequest.approvalRequestStatus,
      validateAndUpdateAR,
      isCoordinator,
      saveDisciplineTabs,
      resetNavigationBlockerAtom
    ]
  );

  const submitOnClickHandler = useCallback(() => {
    setActionInProgress(true);
    validateAndUpdateAR(
      submitARSchema(isCoordinator),
      ApprovalRequestStatus.Submitted,
      AR_FORM.SUBMIT_AR_SUCCESSFUL,
      AR_SUBMIT_ERROR_BANNER
    );
  }, [isCoordinator, validateAndUpdateAR]);

  const modifyOnClickHandler = useCallback(() => {
    validateAndUpdateAR(
      saveARSchema(),
      ApprovalRequestStatus.Draft,
      AR_FORM.MODIFY_AR_SUCCESSFUL,
      AR_MODIFY_ERROR_BANNER
    );
    setModifyDialogOpen(false);
  }, [validateAndUpdateAR]);

  const getCurrentDisciplineAuthComment = useCallback(() => {
    return allAuthorisationComments[approvalRequestDisciplineId ?? ""] ?? [];
  }, [allAuthorisationComments, approvalRequestDisciplineId]);

  const getReturnEnabledStatus = useMemo(() => {
    const enabledCondition = !actionInProgress && !isARDirty && approvalRequest.returnRequestCount === 0;

    if (approvalRequest.approvalRequestStatus !== ApprovalRequestStatus.Distributed) return enabledCondition;

    if (isAtDetailsTab) {
      const assignedDisciplinesAllAuthorised = approvalRequestDisciplines
        .filter((d) => d.primaryApprover === userId || d.secondaryApprover === userId)
        .some((d) => d.status !== ApprovalRequestDisciplineStatus.Authorised);

      return enabledCondition && assignedDisciplinesAllAuthorised;
    } else {
      const currentDisciplineTabIsAuthorised =
        approvalRequestDisciplines.find((d) => d.id === approvalRequestDisciplineId)?.status ===
        ApprovalRequestDisciplineStatus.Authorised;

      return enabledCondition && !currentDisciplineTabIsAuthorised;
    }
  }, [
    approvalRequest.approvalRequestStatus,
    approvalRequest.returnRequestCount,
    approvalRequestDisciplineId,
    approvalRequestDisciplines,
    isARDirty,
    isAtDetailsTab,
    actionInProgress,
    userId
  ]);

  const ACTION_BUTTONS: Record<AR_ACTION_BUTTONS, ARActionButtonProps> = useMemo(
    () => ({
      [AR_ACTION_BUTTONS.Delete]: {
        name: AR_ACTION_BUTTONS.Delete,
        isEnabled: true,
        onClick: () => setDeleteDialogOpen(true),
        component: ARActionButtonComponentName.ButtonGroup,
        isVisible: isAtDetailsTab && isRequestor
      },
      [AR_ACTION_BUTTONS.Save]: {
        name: AR_ACTION_BUTTONS.Save,
        isEnabled: isARDirty && !actionInProgress,
        onClick: () => {
          saveOnClickHandler();
        },
        component: ARActionButtonComponentName.Icon,
        iconName: AR_ACTION_BUTTONS.Save.toLowerCase(),
        isVisible: (isAtDetailsTab && isCoordinator) || isAtDetailsTab || userIsSMEOfDisciplineOrAdmin
      },
      [AR_ACTION_BUTTONS.Submit]: {
        name: AR_ACTION_BUTTONS.Submit,
        isEnabled: !actionInProgress,
        onClick: () => submitOnClickHandler(),
        component: ARActionButtonComponentName.ButtonGroup,
        isVisible: isAtDetailsTab
      },
      [AR_ACTION_BUTTONS.Return]: {
        name: AR_ACTION_BUTTONS.Return,
        isEnabled: getReturnEnabledStatus,
        onClick: () => {
          setReturnDialogOpen(true);
        },
        component: isSME ? ARActionButtonComponentName.Icon : ARActionButtonComponentName.ButtonGroup,
        isVisible: isAtDetailsTab || userIsSMEOfDiscipline
      },
      [AR_ACTION_BUTTONS.Modify]: {
        name: AR_ACTION_BUTTONS.Modify,
        isEnabled: true,
        onClick: () => setModifyDialogOpen(true),
        component: ARActionButtonComponentName.ButtonGroup,
        isVisible: isAtDetailsTab
      },
      [AR_ACTION_BUTTONS.Distribute]: {
        name: AR_ACTION_BUTTONS.Distribute,
        isEnabled: !actionInProgress,
        onClick: () => distributeOnClickHandler(),
        component: ARActionButtonComponentName.ButtonGroup,
        isVisible: isAtDetailsTab
      },
      [AR_ACTION_BUTTONS.SMEAuthorise]: {
        name: BUTTON_LABELS.AUTHORISE,
        isEnabled:
          userIsSMEOfDisciplineOrAdmin &&
          approvalRequest.returnRequestCount === 0 &&
          approvalRequest.approvalRequestStatus === ApprovalRequestStatus.Distributed &&
          getCurrentDisciplineAuthComment().some((commentValue) => commentValue.isChecked) &&
          approvalRequestDisciplines.find((d) => d.id === approvalRequestDisciplineId)?.status !==
            ApprovalRequestDisciplineStatus.Authorised,
        onClick: () => {
          authoriseDisciplineHandler();
        },
        component: ARActionButtonComponentName.ButtonGroup,
        isVisible: userIsSMEOfAr
      },
      [AR_ACTION_BUTTONS.EndorserAuthorise]: {
        name: BUTTON_LABELS.AUTHORISE,
        isEnabled: !actionInProgress,
        onClick: () => authorisedOnClickHandler(),
        component: ARActionButtonComponentName.ButtonGroup,
        isVisible:
          approvalRequest.approvalRequestStatus === ApprovalRequestStatus.DraftPermit &&
          isEndorser &&
          approvalRequest.endorserId === userId // only visible for endorser
      },
      [AR_ACTION_BUTTONS.EndorserReturn]: {
        name: BUTTON_LABELS.RETURN,
        isEnabled: true,
        component: ARActionButtonComponentName.ButtonGroup,
        isVisible:
          approvalRequest.approvalRequestStatus === ApprovalRequestStatus.DraftPermit &&
          isEndorser &&
          approvalRequest.endorserId === userId, // only visible for endorser
        onClick: () => {
          setPermitReturnDialogOpen(true);
        }
      },
      [AR_ACTION_BUTTONS.Amend]: {
        name: AR_ACTION_BUTTONS.Amend,
        isEnabled: true,
        onClick: () => setPermitAmendDialogOpen(true),
        component: ARActionButtonComponentName.ButtonGroup,
        isVisible: approvalRequest.approvalRequestStatus === ApprovalRequestStatus.PermitIssued
      },
      [AR_ACTION_BUTTONS.CreateCopy]: {
        name: BUTTON_LABELS.CREATE_COPY,
        isEnabled: true,
        onClick: () => null,
        component: ARActionButtonComponentName.ButtonGroup,
        isVisible: approvalRequest.approvalRequestStatus !== ApprovalRequestStatus.New
      }
    }),
    [
      isAtDetailsTab,
      isRequestor,
      isARDirty,
      actionInProgress,
      isCoordinator,
      userIsSMEOfDisciplineOrAdmin,
      getReturnEnabledStatus,
      isSME,
      userIsSMEOfDiscipline,
      approvalRequest.returnRequestCount,
      approvalRequest.approvalRequestStatus,
      approvalRequest.endorserId,
      getCurrentDisciplineAuthComment,
      approvalRequestDisciplines,
      userIsSMEOfAr,
      isEndorser,
      userId,
      saveOnClickHandler,
      submitOnClickHandler,
      distributeOnClickHandler,
      approvalRequestDisciplineId,
      authoriseDisciplineHandler,
      authorisedOnClickHandler
    ]
  );

  const memoizedGuestButtonGroup: PartialRecord<ApprovalRequestStatus, ARActionButtonProps[]> = useMemo(() => {
    return {
      [ApprovalRequestStatus.New]: [ACTION_BUTTONS[AR_ACTION_BUTTONS.Save], ACTION_BUTTONS[AR_ACTION_BUTTONS.Submit]],
      [ApprovalRequestStatus.Draft]: [
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Save],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Submit],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Delete],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.CreateCopy]
      ],
      [ApprovalRequestStatus.Submitted]: [
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Modify],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.CreateCopy]
      ],
      [ApprovalRequestStatus.Distributed]: [
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Modify],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.CreateCopy]
      ],
      [ApprovalRequestStatus.DraftPermit]: [
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Modify],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.CreateCopy]
      ],
      [ApprovalRequestStatus.PermitIssued]: [
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Amend],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.CreateCopy]
      ],
      [ApprovalRequestStatus.Completed]: []
    };
  }, [ACTION_BUTTONS]);

  const memoizedCoordinatorButtonGroup: PartialRecord<ApprovalRequestStatus, ARActionButtonProps[]> = useMemo(() => {
    return {
      [ApprovalRequestStatus.New]: [ACTION_BUTTONS[AR_ACTION_BUTTONS.Save], ACTION_BUTTONS[AR_ACTION_BUTTONS.Submit]],
      [ApprovalRequestStatus.Draft]: [
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Save],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Submit],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Delete]
      ],
      [ApprovalRequestStatus.Submitted]: [
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Save],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Distribute],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Return]
      ],
      [ApprovalRequestStatus.Distributed]: [],
      [ApprovalRequestStatus.DraftPermit]: [],
      [ApprovalRequestStatus.PermitIssued]: [],
      [ApprovalRequestStatus.Completed]: []
    };
  }, [ACTION_BUTTONS]);

  const memoizedSMEButtonGroup: PartialRecord<ApprovalRequestStatus, ARActionButtonProps[]> = useMemo(() => {
    const conditionalSaveButton = userIsSMEOfDiscipline ? [ACTION_BUTTONS[AR_ACTION_BUTTONS.Save]] : [];
    const conditionalReturnButton = isBeingAmended(approvalRequestId) ? [] : [ACTION_BUTTONS[AR_ACTION_BUTTONS.Return]];

    return {
      [ApprovalRequestStatus.New]: [],
      [ApprovalRequestStatus.Draft]: [...conditionalSaveButton],
      [ApprovalRequestStatus.Submitted]: [...conditionalSaveButton],
      [ApprovalRequestStatus.Distributed]: [
        ACTION_BUTTONS[AR_ACTION_BUTTONS.Save],
        ...conditionalReturnButton,
        ACTION_BUTTONS[AR_ACTION_BUTTONS.SMEAuthorise]
      ],
      [ApprovalRequestStatus.DraftPermit]: [],
      [ApprovalRequestStatus.PermitIssued]: [],
      [ApprovalRequestStatus.Completed]: []
    };
  }, [ACTION_BUTTONS, approvalRequestId, isBeingAmended, userIsSMEOfDiscipline]);

  const memoizedEndorserButtonGroup: PartialRecord<ApprovalRequestStatus, ARActionButtonProps[]> = useMemo(() => {
    return {
      [ApprovalRequestStatus.New]: [],
      [ApprovalRequestStatus.Draft]: [],
      [ApprovalRequestStatus.Submitted]: [],
      [ApprovalRequestStatus.Distributed]: [],
      [ApprovalRequestStatus.DraftPermit]: [
        ACTION_BUTTONS[AR_ACTION_BUTTONS.EndorserAuthorise],
        ACTION_BUTTONS[AR_ACTION_BUTTONS.EndorserReturn]
      ],
      [ApprovalRequestStatus.PermitIssued]: [],
      [ApprovalRequestStatus.Completed]: []
    };
  }, [ACTION_BUTTONS]);

  const getRoleButtons = (): PartialRecord<ApprovalRequestStatus, ARActionButtonProps[]> => {
    if (isEndorser) {
      return memoizedEndorserButtonGroup;
    } else if (isSME) {
      return memoizedSMEButtonGroup;
    } else if (isCoordinator) {
      return memoizedCoordinatorButtonGroup;
    } else if (isGuest) {
      return memoizedGuestButtonGroup;
    }

    return {
      [ApprovalRequestStatus.New]: [],
      [ApprovalRequestStatus.Draft]: [],
      [ApprovalRequestStatus.Submitted]: [],
      [ApprovalRequestStatus.Distributed]: [],
      [ApprovalRequestStatus.DraftPermit]: [],
      [ApprovalRequestStatus.Completed]: []
    };
  };

  useEffect(() => {
    if (uploadInProgress) {
      setNavigationBlocker({
        title: UPLOAD_IN_PROGRESS_MODAL.TITLE,
        message: UPLOAD_IN_PROGRESS_MODAL.MESSAGE,
        isBlocked: uploadInProgress
      });
    } else if (isARDirty) {
      setNavigationBlocker({
        title: UNSAVED_CHANGES_MODAL.TITLE,
        message: UNSAVED_CHANGES_MODAL.MESSAGE,
        isBlocked: isARDirty && !deleteDialogOpen,
        actionButtons: [
          {
            label: UNSAVED_CHANGES_MODAL.DISMISS_BUTTON,
            onClick: (blocker) => {
              setAllQuestionsAnswered(false);
              reset({ ...approvalRequest }, { keepDirty: false });
              resetNavigationBlockerAtom();
              if (approvalRequestDisciplineId) {
                resetDisciplineTab(approvalRequestDisciplineId);
              }
              blocker.proceed?.();
            }
          },
          {
            label: UNSAVED_CHANGES_MODAL.CONFIRM_BUTTON,
            onClick: (blocker) => {
              saveOnClickHandler(blocker.proceed, blocker.reset);
            }
          }
        ]
      });
    } else {
      setNavigationBlocker({
        title: UPLOAD_IN_PROGRESS_MODAL.TITLE,
        message: UPLOAD_IN_PROGRESS_MODAL.MESSAGE,
        isBlocked: false
      });
    }
  }, [
    approvalRequest,
    approvalRequestDisciplineId,
    isARDirty,
    deleteDialogOpen,
    reset,
    resetDisciplineTab,
    resetNavigationBlockerAtom,
    saveOnClickHandler,
    setAllQuestionsAnswered,
    setNavigationBlocker,
    uploadInProgress
  ]);

  useEffect(() => {
    if (!isError) return;
    const erroredFields = document.getElementsByClassName(FORM_HELPER_ERROR_CLASS.AR_DETAILS);
    if (erroredFields.length > 0) {
      erroredFields[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
  }, [isError]);

  return (
    <ARHeaderAppBar position="fixed">
      <Fragment>
        <Toolbar>
          <div style={{ display: "flex", alignItems: "center" }}>
            <IconButton
              edge="start"
              color="inherit"
              aria-label="back"
              onClick={() => {
                navigate(PATH.MY_REQUESTS);
              }}
              data-testid="arHeaderBackButton"
            >
              <ArrowLeftIcon />
            </IconButton>
            <Typography variant="h5">
              {approvalRequest.referenceNo !== ""
                ? approvalRequest.referenceNo
                : approvalRequest.approvalRequestStatus === ApprovalRequestStatus.New
                  ? AR_FORM.CREATE_MODE_HEADER
                  : AR_FORM.EDIT_MODE_HEADER}{" "}
            </Typography>
            <Typography sx={{ fontSize: "h6.fontSize" }}>
              &nbsp;&nbsp;&nbsp;{approvalRequest.currentVersion.length > 0 && "v"}
              {approvalRequest.currentVersion}
            </Typography>
          </div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              flex: 1
            }}
          >
            <Typography variant="h6">{approvalRequestType.name}</Typography>
            <Chip
              label={AR_STATUSES[approvalRequest.approvalRequestStatus ?? ApprovalRequestStatus.New]}
              color="primary"
              style={{ marginLeft: "1rem" }}
            />
          </div>
          <div
            style={{
              display: (isAtDetailsTab && (isCoordinator || isGuest)) || isSME || isEndorser ? "inline" : "none"
            }}
          >
            <ARHeaderButtonGroup status={approvalRequest.approvalRequestStatus} buttonGroup={getRoleButtons()} />
          </div>
        </Toolbar>
        <Divider />
      </Fragment>
      <ReturnARForm
        isReturn={returnDialogOpen}
        approvalRequestId={approvalRequest.id}
        dismissModal={() => {
          setReturnDialogOpen(false);
        }}
      />
      <ReturnPermitForm
        isReturn={permitReturnDialogOpen}
        approvalRequestId={approvalRequest.id}
        approvalRequestDisciplines={approvalRequestDisciplines}
        dismissModal={() => {
          setPermitReturnDialogOpen(false);
        }}
      />
      <AmendPermitForm
        approvalRequestId={approvalRequest.id}
        isAmend={permitAmendDialogOpen}
        dismissModal={() => {
          setPermitAmendDialogOpen(false);
        }}
      />
      <RedistributeARForm
        approvalRequestId={approvalRequestId}
        dialogOpen={redistributeDialogOpen}
        dismissDialog={() => setRedistributeDialogOpen(false)}
        approvalRequestDisciplines={approvalRequestDisciplines}
        arBasicDetailsValues={arBasicDetailsValues}
        onDistributeMutation={{
          onSuccess: () => {
            setRedistributeDialogOpen(false);
            onDistributeSuccess();
          },
          onError: onDistributeError
        }}
      />
      <RedistributeAmendARForm
        approvalRequestId={approvalRequestId}
        dialogOpen={redistributeAmendDialogOpen}
        dismissDialog={() => setRedistributeAmendDialogOpen(false)}
        approvalRequestDisciplines={approvalRequestDisciplines}
        arBasicDetailsValues={arBasicDetailsValues}
        onDistributeMutation={{
          onSuccess: () => {
            setRedistributeAmendDialogOpen(false);
            onDistributeSuccess();
          },
          onError: onDistributeError
        }}
      />
      <ARHeaderDialog
        approvalRequest={approvalRequest}
        deleteApprovalRequest={deleteApprovalRequest}
        modifyOnClickHandler={modifyOnClickHandler}
        modifyDialogOpen={modifyDialogOpen}
        deleteDialogOpen={deleteDialogOpen}
      />
    </ARHeaderAppBar>
  );
}
