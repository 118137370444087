import {
  AR_BASIC_DETAILS_LOCATORS,
  AR_STATUSES,
  CHANGE_DETAILS_CLASS_NAME,
  HIGHLIGHT_FIELD_CLASS_NAMES
} from "@/constants";
import { useARContext } from "@/context";
import { usePastApprovalRequestById } from "@/hooks";
import { ApprovalRequestVersioned } from "@/interfaces/api/responses/ApprovalRequestVersioned";
import { colours } from "@/theme/colour";
import { dateFormatter, formatDate } from "@/utils";
import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  MenuList,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography
} from "@mui/material";
import { SetStateAction } from "jotai";
import { Dispatch, useEffect, useMemo, useState } from "react";

interface ArDiff {
  fieldName: keyof ApprovalRequestVersioned;
  fieldReferenceId?: string; // Cater for QuestionAnswer | WorkCategory
  originalValue: string;
  currentValue: string;
  updated: string;
  updatedBy: string;
}

interface ARDetailsChangesProps {
  correspondArDetailHighlightKey: string;
  updateCorrespondArDetailFieldKey: Dispatch<SetStateAction<string>>;
  updateDescriptionOriginalValue: Dispatch<SetStateAction<string>>;
  updateDescriptionCurrentValue: Dispatch<SetStateAction<string>>;
}

const ChangesColumnConfig: Record<
  ArDiff["fieldName"],
  { key: string; label: string; showComparison: boolean; render: boolean }
> = {
  title: { key: AR_BASIC_DETAILS_LOCATORS.TITLE_FIELD, label: "Title", showComparison: true, render: true },
  hub: { key: AR_BASIC_DETAILS_LOCATORS.HUB_FIELD, label: "Hub", showComparison: true, render: true },
  site: { key: AR_BASIC_DETAILS_LOCATORS.SITE_FIELD, label: "Site", showComparison: true, render: true },
  businessUnit: {
    key: AR_BASIC_DETAILS_LOCATORS.BUSINESS_UNIT_FIELD,
    label: "Business Unit",
    showComparison: true,
    render: true
  },
  project: { key: AR_BASIC_DETAILS_LOCATORS.PROJECT_FIELD, label: "Project", showComparison: true, render: true },
  description: {
    key: AR_BASIC_DETAILS_LOCATORS.DESCRIPTION_FIELD,
    label: "Description",
    showComparison: false,
    render: true
  },
  costCode: { key: AR_BASIC_DETAILS_LOCATORS.COST_CODE_FIELD, label: "Cost Code", showComparison: true, render: true },
  requiredByDate: {
    key: AR_BASIC_DETAILS_LOCATORS.REQUIRED_BY_DATE_FIELD,
    label: "Required By",
    showComparison: true,
    render: true
  },
  // TODO: Figure out these
  lastModified: {
    key: "",
    label: "",
    showComparison: true,
    render: false
  },
  lastModifiedByName: {
    key: "",
    label: "",
    showComparison: true,
    render: false
  },
  id: { key: "", label: "", showComparison: true, render: false },
  answerItems: { key: "", label: "", showComparison: true, render: false },
  workCategories: { key: "", label: "", showComparison: true, render: false }
};

export function ARDetailsChanges({
  correspondArDetailHighlightKey,
  updateCorrespondArDetailFieldKey,
  updateDescriptionOriginalValue,
  updateDescriptionCurrentValue
}: ARDetailsChangesProps) {
  const [selectedVersion, setSelectedVersion] = useState<number | undefined>(undefined);
  const dateTimeFormat: string = "h:mm A, D MMMM YYYY";
  const versionSelectLabel: string = "Comparing changes from";

  const { approvalRequest } = useARContext();

  const { data: currentAr } = usePastApprovalRequestById(approvalRequest.id, approvalRequest.currentVersion);
  const { data: pastAr } = usePastApprovalRequestById(approvalRequest.id, selectedVersion?.toString());

  const onSelectVersion = (event: SelectChangeEvent) => {
    setSelectedVersion(approvalRequest.pastApprovalRequests[Number(event.target.value)].approvalRequestVersion);
    updateCorrespondArDetailFieldKey("");
  };

  const arDiff = useMemo(() => {
    const diffs: ArDiff[] = [];

    if (pastAr === undefined) return diffs;

    for (const key in currentAr) {
      if (currentAr[key as keyof ApprovalRequestVersioned] !== pastAr[key as keyof ApprovalRequestVersioned]) {
        let pastARValue = pastAr[key as keyof ApprovalRequestVersioned];
        let currentARValue = currentAr[key as keyof ApprovalRequestVersioned];

        if (key === "lastModified" || key === "requiredByDate") {
          pastARValue = formatDate(pastARValue) ?? "";
          currentARValue = formatDate(currentARValue) ?? "";

          if (pastARValue === currentARValue) continue;
        }

        if (key === "description") {
          updateDescriptionOriginalValue(pastARValue);
          updateDescriptionCurrentValue(currentARValue);
        }

        // TEMPORARY skip since it's not part of current US's focus
        if (key === "answerItems" || key === "workCategories") continue;

        diffs.push({
          fieldName: key as keyof ApprovalRequestVersioned,
          originalValue: pastARValue,
          currentValue: currentARValue,
          updatedBy: pastAr.lastModifiedByName,
          updated: dateFormatter(pastAr.lastModified, dateTimeFormat) ?? ""
        });
      }
    }

    return diffs;
  }, [currentAr, pastAr, updateDescriptionOriginalValue, updateDescriptionCurrentValue]);

  useEffect(() => {
    if (correspondArDetailHighlightKey === "") return;
    const highlightedFields =
      document.getElementsByClassName(HIGHLIGHT_FIELD_CLASS_NAMES.BACKGROUND) ||
      document.getElementsByClassName(HIGHLIGHT_FIELD_CLASS_NAMES.BORDER);
    if (highlightedFields.length > 0) {
      highlightedFields[0].scrollIntoView({ behavior: "smooth", block: "center", inline: "nearest" });
    }
  }, [correspondArDetailHighlightKey]);

  return (
    <Box bgcolor={colours.sidePanel} sx={{ height: "100%", p: "1rem" }} overflow="auto">
      <FormControl fullWidth sx={{ marginTop: "1rem" }} data-testid="version-select">
        <InputLabel>{versionSelectLabel}</InputLabel>
        <Select onChange={onSelectVersion} label={versionSelectLabel}>
          {approvalRequest.pastApprovalRequests.map((ar, idx) => (
            <MenuItem value={idx}>
              Version {ar.approvalRequestVersion} - {AR_STATUSES[ar.status]}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <MenuList>
        {selectedVersion !== undefined &&
          (arDiff.length > 0 ? (
            arDiff.map(
              (ar, idx) =>
                ChangesColumnConfig[ar.fieldName].render && (
                  <Card variant="outlined" sx={{ marginTop: "1rem" }} data-testid={"diff-card-" + idx}>
                    <CardActionArea
                      className={
                        correspondArDetailHighlightKey === ChangesColumnConfig[ar.fieldName].key
                          ? CHANGE_DETAILS_CLASS_NAME
                          : ""
                      }
                      onClick={() => updateCorrespondArDetailFieldKey(ChangesColumnConfig[ar.fieldName].key)}
                    >
                      <CardContent>
                        <Typography sx={{ fontWeight: "bold", fontSize: "h6.fontSize" }} gutterBottom>
                          {ChangesColumnConfig[ar.fieldName].label}
                        </Typography>
                        {ChangesColumnConfig[ar.fieldName].showComparison && (
                          <Grid container spacing={2}>
                            <Tooltip title={ar.currentValue ?? ""}>
                              <Grid item md={6}>
                                <Typography color="#00000099" gutterBottom noWrap>
                                  New Value
                                </Typography>
                                <Typography sx={{ fontWeight: "500" }} gutterBottom noWrap>
                                  {ar.currentValue}
                                </Typography>
                              </Grid>
                            </Tooltip>
                            <Tooltip title={ar.originalValue ?? ""}>
                              <Grid item md={6}>
                                <Typography color="#00000099" gutterBottom>
                                  Original Value
                                </Typography>
                                <Typography sx={{ fontWeight: "500" }} gutterBottom>
                                  {ar.originalValue}
                                </Typography>
                              </Grid>
                            </Tooltip>
                          </Grid>
                        )}

                        <Typography color="#00000099" gutterBottom noWrap>
                          Modified • {ar.updated} • {ar.updatedBy}
                        </Typography>
                      </CardContent>
                    </CardActionArea>
                  </Card>
                )
            )
          ) : (
            <>No changes made</>
          ))}
      </MenuList>
    </Box>
  );
}
