import { useState } from "react";
import MapView from "@arcgis/core/views/MapView";
import AreaMeasurement2DViewModel from "@arcgis/core/widgets/AreaMeasurement2D/AreaMeasurement2DViewModel";
import DistanceMeasurement2DViewModel from "@arcgis/core/widgets/DistanceMeasurement2D/DistanceMeasurement2DViewModel";
import { ApprovalRequestStatus } from "@/interfaces";

import {
  MapAnalysis,
  MapUploadIcon,
  MapDistance,
  MapDownload,
  MapRecenter,
  MapArea,
  MapFullScreen,
  MapReset,
  MapZoom
} from "@/assets/icons";
import "./MapToolbox.css";

interface MapToolboxProps {
  setFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  mapView: MapView;
  approvalRequestStatus?: ApprovalRequestStatus;
}

export function MapToolbox({ setFullScreen, mapView, approvalRequestStatus }: MapToolboxProps) {
  const [clickedButtons, setClickedButtons] = useState<string[]>([]);
  const activeMeasureStatus = ["ready", "measuring"];
  const noAction = () => {};
  const enableFullScreen = () => setFullScreen(true);
  const disableFullScreen = () => setFullScreen(false);
  const isDisabled = approvalRequestStatus !== ApprovalRequestStatus.Draft;

  const [areaMeasurementTool] = useState<AreaMeasurement2DViewModel>(
    new AreaMeasurement2DViewModel({
      view: mapView,
      unit: "metric"
    })
  );
  const [distanceMeasurementTool] = useState<DistanceMeasurement2DViewModel>(
    new DistanceMeasurement2DViewModel({
      view: mapView,
      unit: "metric"
    })
  );

  const getDisabledActions = (enableLabel: string): string[] => {
    const disabledActions = [];
    if (
      enableLabel === "Area" &&
      clickedButtons.includes("Distance") &&
      activeMeasureStatus.includes(distanceMeasurementTool.state)
    ) {
      disabledActions.push("Distance");
    }

    if (
      enableLabel === "Distance" &&
      clickedButtons.includes("Area") &&
      activeMeasureStatus.includes(areaMeasurementTool.state)
    ) {
      disabledActions.push("Area");
    }

    return disabledActions;
  };

  const enableAreaMeasurement = () => areaMeasurementTool.start();
  const disableAreaMeasurement = () => {
    areaMeasurementTool.clear();
    // refresh map
    mapView.extent = mapView.extent;
  };

  const enableDistanceMeasurement = () => distanceMeasurementTool.start();
  const disableDistanceMeasurement = () => {
    distanceMeasurementTool.clear();
    // refresh map
    mapView.extent = mapView.extent;
  };

  const panelActionProps = [
    {
      groupLabel: "Shape Files",
      groupItems: [
        {
          icon: MapUploadIcon,
          label: "Upload",
          enabledAction: noAction,
          disabledAction: noAction,
          uploadButtonDisabled: isDisabled
        },
        { icon: MapDownload, label: "Download", enabledAction: noAction, disabledAction: noAction }
      ]
    },
    {
      groupLabel: "View",
      groupItems: [
        { icon: MapZoom, label: "Zoom to Extents", enabledAction: noAction, disabledAction: noAction },
        { icon: MapRecenter, label: "Re-center", enabledAction: noAction, disabledAction: noAction },
        {
          icon: MapFullScreen,
          label: "Full Screen",
          enabledAction: enableFullScreen,
          disabledAction: disableFullScreen
        }
      ]
    },
    {
      groupLabel: "Measure",
      groupItems: [
        {
          icon: MapDistance,
          label: "Distance",
          enabledAction: enableDistanceMeasurement,
          disabledAction: disableDistanceMeasurement
        },
        { icon: MapArea, label: "Area", enabledAction: enableAreaMeasurement, disabledAction: disableAreaMeasurement }
      ]
    },
    {
      groupLabel: "Validation",
      groupItems: [
        { icon: MapAnalysis, label: "Sensitivity Analysis", enabledAction: noAction, disabledAction: noAction },
        { icon: MapReset, label: "Reset", enabledAction: noAction, disabledAction: noAction }
      ]
    }
  ];

  const buttonClicked = (
    label: string,
    buttonDisabled: boolean = false,
    enabledAction: () => void,
    disabledAction: () => void
  ) => {
    if (!clickedButtons.includes(label) && !buttonDisabled) {
      const disabledButtons = getDisabledActions(label);
      setClickedButtons([...clickedButtons.filter((enabledLabel) => !disabledButtons.includes(enabledLabel)), label]);
      enabledAction();
    } else {
      setClickedButtons(clickedButtons.filter((enabledLabel) => enabledLabel !== label));
      disabledAction();
    }
  };

  const buttonContent = (
    label: string,
    IconSvg: React.FunctionComponent<React.ComponentProps<"svg">>,
    enabledAction: () => void,
    disabledAction: () => void,
    buttonDisabled?: boolean
  ) => (
    <div
      className={
        "panel-button " + (clickedButtons.includes(label) && label !== "Upload" ? "panel-button-selected" : "")
      }
      onClick={() => buttonClicked(label, buttonDisabled, enabledAction, disabledAction)}
      data-testid={`map-tool-${label}`}
    >
      <span className="panel-button-icon">
        {buttonDisabled ? (
          <IconSvg fill="grey" cursor="not-allowed" />
        ) : !buttonDisabled ? (
          <label htmlFor="fileInput">
            <IconSvg fill="black" cursor="pointer" />
          </label>
        ) : (
          <IconSvg fill={clickedButtons.includes(label) ? "#005BD1DE" : "black"} />
        )}
      </span>
      <span style={{ color: buttonDisabled ? "grey" : "black" }} className="panel-button-text">
        {label === "Upload" && !buttonDisabled ? <label htmlFor="fileInput">{label}</label> : label}
      </span>
    </div>
  );

  return (
    <div id="command-panel">
      {panelActionProps.map(({ groupLabel, groupItems }, index) => (
        <>
          {index === 0 ? "" : <div className="panel-divider" />}
          <div className="panel-group">
            <div className="panel-group-buttons">
              {groupItems.map(({ label, icon, enabledAction, disabledAction, uploadButtonDisabled }) => (
                <>{buttonContent(label, icon, enabledAction, disabledAction, uploadButtonDisabled)}</>
              ))}
            </div>
            <div className="panel-group-text">{groupLabel}</div>
          </div>
        </>
      ))}
    </div>
  );
}
