import { useEffect, useMemo, useState } from "react";
import { Grid, IconButton, Stack, TextField, Typography } from "@mui/material";
import { DataGridPro, GridColDef, GridEventListener, GridRowParams } from "@mui/x-data-grid-pro";
import { FilterAlt as FilterAltIcon } from "@mui/icons-material";
import { generatePath, useNavigate, useSearchParams } from "react-router-dom";
import { Search } from "@mui/icons-material";

import { DashboardSelectWithGrid } from "@/components/shared";
import { APPROVAL_REQUEST_PATH, SEARCH_TERM_MAX_LENGTH } from "@/constants";
import { flattenPaginatedARList, formatDate, renderDisciplineStatusIcon } from "@/utils";
import {
  useHubList,
  useProjectList,
  useSitesByHubId,
  useBusinessUnitList,
  usePagination,
  useGetARSearch,
  useApprovalRequestTypeList
} from "@/hooks";
import { IOption, ARSearchClearingOption } from "@/interfaces";

export const ARSearch = () => {
  const defaultPageSize = 50;
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const [searchTerm, setSearchTerm] = useState<string>();
  const [peopleSearchTerm, setPeopleSearchTerm] = useState<string>();
  const [showAdvancedSearch, setShowAdvancedSearch] = useState<boolean>(false);
  const [selectedHubId, setSelectedHubId] = useState<string | undefined>();
  const [selectedSiteId, setSelectedSiteId] = useState<string | undefined>();
  const [selectedBusinessUnitId, setSelectedBusinessUnitId] = useState<string | undefined>();
  const [selectedProjectId, setSelectedProjectId] = useState<string | undefined>();
  const [selectedApprovalRequestTypeId, setSelectedApprovalRequestTypeId] = useState<string | undefined>();
  const [selectedClearingOption, setClearingOption] = useState<string | undefined>();
  const { currentPage, pageSize, paginationModelChangeHandler } = usePagination(0, defaultPageSize);

  const { data: hubList } = useHubList();
  const { data: siteList } = useSitesByHubId(selectedHubId);
  const { data: projectList } = useProjectList(true);
  const { data: businessUnitList } = useBusinessUnitList(true);
  const { data: approvalRequestTypeList } = useApprovalRequestTypeList(true);
  const {
    data: searchResult,
    isFetching: searchResultIsLoading,
    refetch: searchResultRefetch
  } = useGetARSearch(
    decodeURIComponent(searchParams.get("q") ?? "")?.trim(),
    showAdvancedSearch ? decodeURIComponent(searchParams.get("p") ?? "")?.trim() : undefined,
    selectedHubId,
    selectedSiteId,
    selectedBusinessUnitId,
    selectedProjectId,
    showAdvancedSearch ? selectedApprovalRequestTypeId : undefined,
    showAdvancedSearch ? selectedClearingOption : undefined,
    currentPage + 1,
    pageSize
  );

  useEffect(() => {
    const searchTerm = decodeURIComponent(searchParams.get("q") ?? "");
    const peopleSearchTerm = decodeURIComponent(searchParams.get("p") ?? "");
    setSearchTerm(searchTerm);
    setPeopleSearchTerm(peopleSearchTerm);

    if (!showAdvancedSearch) {
      setShowAdvancedSearch(peopleSearchTerm ? true : false);
    }
  }, [searchParams]);

  useEffect(() => {
    searchResultRefetch();
  }, [
    selectedHubId,
    selectedSiteId,
    selectedBusinessUnitId,
    selectedProjectId,
    selectedApprovalRequestTypeId,
    selectedClearingOption,
    currentPage,
    pageSize,
    searchParams,
    searchResultRefetch,
    showAdvancedSearch
  ]);

  const clearingFilterOptions: IOption[] = [
    { id: ARSearchClearingOption.Clearing.toString(), value: "Clearing" },
    { id: ARSearchClearingOption.NoClearing.toString(), value: "No Clearing" }
  ];

  const dataColumns: GridColDef[] = [
    {
      field: "referenceNo",
      headerName: "AR Number",
      sortable: false,
      flex: 0.25
    },
    {
      field: "title",
      headerName: "Title",
      sortable: false,
      flex: 1
    },
    {
      field: "heritage",
      headerName: "H",
      description: "Heritage",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "csp",
      headerName: "CSP",
      description: "CSP",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "biological",
      headerName: "B",
      description: "Biological",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "environmentalOps",
      headerName: "EO",
      description: "Environmental Ops",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "tenure",
      headerName: "T",
      description: "Tenure",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "stateAgreement",
      headerName: "SA",
      description: "State Agreement",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "miningAct",
      headerName: "M",
      description: "Mining Act",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "water",
      headerName: "W",
      description: "Water",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "environmentalApprovals",
      headerName: "EA",
      description: "Environmental Approvals",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "partV",
      headerName: "V",
      description: "Part V",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "nvcp",
      headerName: "NVCP",
      description: "Native Vegetation Clearing Permit",
      headerAlign: "center",
      sortable: false,
      flex: 0.15,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "shire",
      headerName: "Sh",
      description: "Shire",
      headerAlign: "center",
      sortable: false,
      flex: 0.1,
      renderCell: renderDisciplineStatusIcon
    },
    {
      field: "projectName",
      headerName: "Project",
      sortable: false,
      flex: 0.35
    },
    {
      field: "requiredByDate",
      headerName: "Required By",
      sortable: false,
      valueFormatter: (params) => formatDate(params.value),
      flex: 0.35
    }
  ];

  const flattenedARs = useMemo(() => {
    if (!searchResult) return [];
    return flattenPaginatedARList(searchResult);
  }, [searchResult]);

  const handleRowClick: GridEventListener<"rowClick"> = async (params: GridRowParams) => {
    const path = generatePath(APPROVAL_REQUEST_PATH.DETAILS, { approvalRequestId: params.row.id });
    navigate(path);
  };

  const handleOnSearchFieldKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === "Enter") {
      handleSetSearchParams();
    }
  };

  const handleSetSearchParams = () => {
    setSearchParams({ q: encodeURIComponent(searchTerm ?? ""), p: encodeURIComponent(peopleSearchTerm ?? "") });
  };

  return (
    <Stack marginX={1} marginTop={2} rowGap={3}>
      <Typography sx={{ fontSize: 16 }}>Search</Typography>
      <Grid container columnGap={1} rowGap={3}>
        <Grid item flexGrow={1}>
          <TextField
            size="small"
            label="Search"
            placeholder="Search for a request..."
            fullWidth
            InputProps={{
              startAdornment: (
                <IconButton sx={{ mr: 1 }} onClick={() => handleSetSearchParams()}>
                  <Search />
                </IconButton>
              ),
              sx: {
                background: "white"
              }
            }}
            inputProps={{
              "data-testid": "search-page-field",
              onKeyDown: handleOnSearchFieldKeyDown,
              maxLength: SEARCH_TERM_MAX_LENGTH
            }}
            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
              setSearchTerm(event.target.value);
            }}
            value={searchTerm}
          />
        </Grid>
        <DashboardSelectWithGrid
          id="hubId"
          label="Hub"
          options={hubList}
          value={selectedHubId}
          onChange={(event) => {
            setSelectedSiteId(undefined);
            setSelectedHubId(event.target.value);
          }}
          data-testid="hub-filter-select"
        />
        <DashboardSelectWithGrid
          id="siteId"
          label="Site"
          options={siteList}
          value={selectedSiteId}
          onChange={(event) => {
            setSelectedSiteId(event.target.value);
          }}
          data-testid="site-filter-select"
        />
        <DashboardSelectWithGrid
          id="businessUnitId"
          label="Business Unit"
          options={businessUnitList}
          value={selectedBusinessUnitId}
          onChange={(event) => {
            setSelectedBusinessUnitId(event.target.value);
          }}
          data-testid="business-unit-filter-select"
        />
        <DashboardSelectWithGrid
          id="projectId"
          label="Project"
          options={projectList}
          value={selectedProjectId}
          onChange={(event) => {
            setSelectedProjectId(event.target.value);
          }}
          data-testid="project-filter-select"
        />
        <Grid item>
          <IconButton data-testid="advanced-search-icon" onClick={() => setShowAdvancedSearch((old) => !old)}>
            <FilterAltIcon />
          </IconButton>
        </Grid>
      </Grid>
      {showAdvancedSearch ? (
        <Grid container columnGap={1} rowGap={3}>
          <Grid item xs={6} sm={6} md={3} lg={3}>
            <TextField
              size="small"
              label="People"
              placeholder="Search by name..."
              fullWidth
              InputProps={{
                endAdornment: (
                  <IconButton onClick={() => handleSetSearchParams()}>
                    <Search />
                  </IconButton>
                ),
                sx: {
                  background: "white"
                }
              }}
              inputProps={{
                "data-testid": "search-people-field",
                onKeyDown: handleOnSearchFieldKeyDown,
                maxLength: SEARCH_TERM_MAX_LENGTH
              }}
              onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                setPeopleSearchTerm(event.target.value);
              }}
              value={peopleSearchTerm}
            />
          </Grid>
          <DashboardSelectWithGrid
            id="typeId"
            label="Approval Request Type"
            options={approvalRequestTypeList}
            value={selectedApprovalRequestTypeId}
            onChange={(event) => {
              setSelectedApprovalRequestTypeId(event.target.value);
            }}
            data-testid="ar-type-filter-select"
          />
          <DashboardSelectWithGrid
            id="clearingOption"
            label="Clearing"
            options={clearingFilterOptions}
            value={selectedClearingOption?.toString()}
            defaultValue="default"
            onChange={(event) => {
              setClearingOption(event.target.value);
            }}
            defaultOption={{ id: "all", value: "Clearing and no clearing" }}
            data-testid="clearing-filter-select"
          />
        </Grid>
      ) : null}
      <DataGridPro
        data-testid="search-result-table"
        sx={{ border: 0, cursor: "pointer" }}
        density="compact"
        autoHeight={true}
        disableColumnFilter
        disableColumnReorder
        disableColumnMenu
        disableChildrenSorting
        disableChildrenFiltering
        disableMultipleColumnsFiltering
        disableMultipleRowSelection
        columns={dataColumns}
        rows={flattenedARs}
        rowCount={searchResult?.totalRecords}
        loading={searchResultIsLoading}
        pagination={true}
        paginationMode="server"
        pageSizeOptions={[defaultPageSize, 100, 150, 200, 250]}
        paginationModel={{
          page: currentPage,
          pageSize: pageSize
        }}
        onPaginationModelChange={paginationModelChangeHandler}
        onRowClick={handleRowClick}
        getRowId={(row) => row.id}
      />
    </Stack>
  );
};
