import { useMemo, useState } from "react";
import { diffWordsWithSpace } from "diff";
import { Box, Stack, TextField } from "@mui/material";

export function DiffViewer() {
  const [valueOne, setValueOne] = useState<string>("Hello");
  const [valueTwo, setValueTwo] = useState<string>("Hello there");

  const diffContent = useMemo(() => {
    const changes = diffWordsWithSpace(valueOne, valueTwo);

    const diffedValues: JSX.Element[] = [];

    changes.forEach((diff) => {
      if (diff.added) {
        diffedValues.push(<ins>{diff.value}</ins>);
      } else if (diff.removed) {
        diffedValues.push(<del>{diff.value}</del>);
      } else {
        diffedValues.push(<span>{diff.value}</span>);
      }
    });

    return diffedValues;
  }, [valueOne, valueTwo]);

  return (
    <Stack p={2} spacing={2}>
      <TextField value={valueOne} onChange={({ target: { value } }) => setValueOne(value)} />
      <TextField value={valueTwo} onChange={({ target: { value } }) => setValueTwo(value)} />
      <Box
        sx={{
          del: {
            color: "red"
          },
          ins: {
            color: "green"
          }
        }}
      >
        {diffContent}
      </Box>
    </Stack>
  );
}
