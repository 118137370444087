import { useEffect, useState } from "react";
import { Box, Button, Card, CardContent, CardHeader, FormControl, Typography } from "@mui/material";
import { useGetHubsData, useCreateNewHub, useDeleteHub, useGetHubById, useUpdateHub } from "@/hooks";
import { BUTTON_LABELS, DEFAULT_PAGE_SIZES, HUB_MESSAGE, STATEMENT_ACTION, SUB_HEADERS, TITLES } from "@/constants";
import { GridColDef, GridRowModel, GridRowSelectionModel } from "@mui/x-data-grid";
import { usePagination } from "@/hooks";
import { DataGridPro } from "@mui/x-data-grid-pro";
import { AddHubRequest, HubItem, PutHubRequest, UpdateHubRequest } from "@/interfaces";
import { LoadingButton } from "@mui/lab";
import { useSetAtom } from "jotai";
import { snackBarAtom } from "@/stores";
import { Dialog } from "@/components/dialogs";
import { useForm } from "react-hook-form";
import { AppFormInput } from "@/components/fields";
import { hubSchema } from "@/validations";
import { ValidationError } from "yup";

export default function HubsConfigurationItem() {
  const setSnackBar = useSetAtom(snackBarAtom);
  const [hubDialogOpen, setHubDialogOpen] = useState(false);
  const [editIsActive, setEditIsActive] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState<string | undefined>(undefined);
  const [selectedHubIds, setSelectedHubIds] = useState<string[]>([]);
  const { currentPage, pageSize, paginationModelChangeHandler } = usePagination();

  const {
    control,
    getValues,
    watch,
    reset,
    setError,
    formState: { errors }
  } = useForm<HubItem>({
    defaultValues: {
      name: ""
    }
  });

  const hubFormValues = watch(["name"]);
  const formValues = getValues();

  const columns: GridColDef[] = [
    {
      field: "name",
      headerName: "Name",
      flex: 1
    }
  ];

  const { data: result, isLoading } = useGetHubsData(currentPage + 1, pageSize);

  const { data: selectedHub, isSuccess: hubSuccess } = useGetHubById(selectedRowId!);

  const {
    mutate: addHubMutation,
    isLoading: addInProgress,
    isError
  } = useCreateNewHub(() => {
    setHubDialogOpen(false);
    setSnackBar({
      message: HUB_MESSAGE.SAVE_SUCCESSFUL,
      open: true
    });
  });

  const { mutate: updateHubMutation, isLoading: updateInProgress } = useUpdateHub(() => {
    setHubDialogOpen(false);
    setSnackBar({
      message: HUB_MESSAGE.EDIT_SUCCESSFUL,
      open: true
    });
  });

  const { mutate: deleteHubMutation } = useDeleteHub(() => {
    setHubDialogOpen(false);
    setSnackBar({
      message: HUB_MESSAGE.DELETE_SUCCESSFUL,
      open: true
    });
  });

  const handleEditOnclick = () => {
    if (hubSuccess) {
      reset({ name: selectedHub.name });
      setHubDialogOpen(true);
    }
  };

  useEffect(() => {
    if (!isLoading) {
      return;
    }

    if (isError) {
      setHubDialogOpen(false);
      reset({});
    }
  }, [hubFormValues, isLoading, isError, reset, formValues]);

  const validateAndSaveHub = () => {
    if (editIsActive) {
      const updateHubCommand: UpdateHubRequest = {
        id: selectedRowId!,
        name: formValues.name
      };
      hubSchema
        .validate(updateHubCommand, { abortEarly: false })
        .then(() => updateHubMutation(updateHubCommand))
        .catch(({ inner }: ValidationError) => {
          inner.forEach(({ path, message }) => {
            setError(path as keyof HubItem, { message });
          });
        });
    } else {
      const addNewHubCommand: AddHubRequest = {
        name: formValues.name
      };
      hubSchema
        .validate(addNewHubCommand, { abortEarly: false })
        .then(() => addHubMutation(addNewHubCommand))
        .catch(({ inner }: ValidationError) => {
          inner.forEach(({ path, message }) => {
            setError(path as keyof HubItem, { message });
          });
        });
    }
  };

  const startEdit = (item: GridRowModel) => {
    setEditIsActive(true);
    setSelectedRowId(item.id.toString());
    setHubDialogOpen(true);
  };

  const handleHubDeletion = () => {
    const deleteHubCommand: PutHubRequest = {
      id: selectedHubIds,
      action: STATEMENT_ACTION.DELETED
    };
    deleteHubMutation(deleteHubCommand);
  };

  function GridActionButtons() {
    return (
      <Box>
        <LoadingButton
          data-testid="hub-button-add"
          variant="text"
          onClick={() => {
            setHubDialogOpen(true);
            setEditIsActive(false);
          }}
          disabled={false}
          loading={addInProgress || updateInProgress}
        >
          Add
        </LoadingButton>
        <Button
          data-testid="hub-button-edit"
          variant="text"
          onClick={() => {
            handleEditOnclick();
          }}
          disabled={!editIsActive}
        >
          Edit
        </Button>
        <Button
          data-testid="hub-button-delete"
          variant="text"
          onClick={() => {
            handleHubDeletion();
          }}
          disabled={!editIsActive}
        >
          Delete
        </Button>
      </Box>
    );
  }

  function CustomNoRowsOverlay() {
    return (
      <Typography
        sx={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          height: "100%"
        }}
      >
        No rows
      </Typography>
    );
  }

  return (
    <Card sx={{ flexGrow: 1 }}>
      <CardHeader title={TITLES.hubs} subheader={SUB_HEADERS.hubs} action={<GridActionButtons />} />
      <CardContent>
        <DataGridPro
          autoHeight={true}
          paginationMode="server"
          data-testid="admin-table-Id"
          columns={columns}
          rows={result.items}
          rowCount={result.totalRecords}
          getRowId={(row: HubItem) => row.id}
          checkboxSelection
          pagination={true}
          paginationModel={{
            pageSize: pageSize,
            page: currentPage
          }}
          loading={isLoading}
          onPaginationModelChange={paginationModelChangeHandler}
          pageSizeOptions={DEFAULT_PAGE_SIZES}
          slots={{
            noResultsOverlay: CustomNoRowsOverlay,
            noRowsOverlay: CustomNoRowsOverlay
          }}
          onRowSelectionModelChange={(ids: GridRowSelectionModel) => {
            if (ids.length > 0) {
              setSelectedHubIds(ids.map((id) => id.toString()));
              setSelectedRowId(ids[0].toString());
            }
            if (ids.length > 0) {
              setEditIsActive(true);
            } else {
              setEditIsActive(false);
            }
          }}
          onRowDoubleClick={(item: GridRowModel) => {
            startEdit(item);
          }}
        />
      </CardContent>

      <Dialog
        data-testid={"admin-hub-modal"}
        open={hubDialogOpen}
        onClose={() => {
          setHubDialogOpen(false);
          reset({});
        }}
        title={editIsActive ? "Update Hub" : "New Hub"}
        actions={[
          {
            label: BUTTON_LABELS.CANCEL,
            onClick: () => {
              setHubDialogOpen(false);
              reset({});
            }
          },
          {
            label: editIsActive ? BUTTON_LABELS.SAVE : BUTTON_LABELS.ADD,
            disabled: false,
            onClick: () => {
              validateAndSaveHub();
              reset({});
            }
          }
        ]}
      >
        <FormControl sx={{ width: "21rem", height: "3.5rem" }}>
          <AppFormInput
            data-testid="hub-name-input"
            name={"name"}
            label={"Name"}
            control={control}
            required={true}
            error={errors.name}
            showError={true}
            size="small"
            maxLength={500}
          />
        </FormControl>
      </Dialog>
    </Card>
  );
}
